import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '../Toggle';

import './SocialAuthentication.style.scss';

/** @namespace ScandiPWA/SocialLoginGraphql/Component/SocialAuthentication/Component */
export class SocialAuthentication extends PureComponent {
    static propTypes = {
        buttonsList: PropTypes.arrayOf(PropTypes.shape),
        isMyAccount: PropTypes.bool
    };

    static defaultProps = {
        buttonsList: null,
        isMyAccount: false
    };

    __construct(props) {
        super.__construct(props);

        this.renderButton = this.renderButton.bind(this);
    }

    /**
     * Prepare parameters to place popup screen in the middle of the screen
     *
     * @returns {string}
     */
    getPopupParams() {
        const bottomPadding = 22;

        const screenX = window.screenX || window.screenLeft;
        const screenY = window.screenY || window.screenTop;
        const outerWidth = window.outerWidth || document.body.clientWidth;
        const outerHeight = window.outerHeight || document.body.clientHeight - bottomPadding;

        const width = 500;
        const height = 420;

        const leftDivider = 2;
        const topDivider = 2.5;

        const left = parseInt(screenX + ((outerWidth - width) / leftDivider), 10);
        const top = parseInt(screenY + ((outerHeight - height) / topDivider), 10);

        return `width=${width},height=${height},left=${left},top=${top}`;
    }

    /**
     * Open new browser window
     *
     * @param type
     * @param url
     */
    handleLogin(type, url) {
        window.open(
            `${url}&isAjax=true`,
            type,
            this.getPopupParams()
        );
    }

    renderButton(buttonData, index) {
        const { isMyAccount } = this.props;
        const {
            type,
            label,
            url
        } = buttonData;

        if (isMyAccount) {
            return (
                <div block="SocialAuthentication" elem="Item" key={ type }>
                    <button
                      block="Button"
                      elem="Social"
                      mods={ { type } }
                      name={ label }
                      onClick={ () => this.handleLogin(type, url) }
                    />
                    <div
                      block="SocialAuthentication"
                      elem="ToggleWrapper"
                      onClick={ () => this.handleLogin(type, url) }
                      onKeyPress={ () => {} }
                      role="button"
                      tabIndex="0"
                    >
                        <Toggle
                          id={ type }
                          isChecked={ false }
                          label={ __('Connect') }
                        />
                    </div>
                </div>
            );
        }

        return (
            <button
              key={ index }
              block="Button"
              elem="Social"
              mods={ { type } }
              name={ label }
              onClick={ () => this.handleLogin(type, url) }
            />
        );
    }

    render() {
        const { buttonsList, isMyAccount } = this.props;

        if (!buttonsList || !buttonsList.length) {
            return null;
        }

        return (
            <div block="SocialAuthentication" mods={ { isMyAccount } }>
                { buttonsList.map(this.renderButton) }
            </div>
        );
    }
}

export default SocialAuthentication;
