import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { appendWithStoreCode } from 'Util/Url';
export const PaymentResponse = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */ 
    '../route/PaymentResponse'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: <Route path={ appendWithStoreCode('/tappay/payment/response') } render={ (props) => <PaymentResponse { ...props } /> } />,
        position: 100
    },
 ];

 export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};