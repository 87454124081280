/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from "prop-types";
import { PureComponent } from "react";

import "./SmilepayatmOrderSuccess.style";

import {
  formatPrice
} from 'Util/Price';

/** @namespace Component/SmilepayatmOrderSuccess/Component */
export class SmilepayatmOrderSuccess extends PureComponent {
  
  static propTypes = {
    details: PropTypes.object.isRequired,
    config: PropTypes.object 
  };
 
  renderSmilepayPaymentMethod() {
    const {
      config:{
        smilepay_atm:{
          smilepayatm_title
        }
      }
    } = this.props
    return (
      <div block="Smilepay" elem="PaymentMethod">
          <h2 block="Smilepay" elem="Heading">{ smilepayatm_title }</h2>
          <div block="Smilepay" elem="Content">
            { this.renderContent() }
          </div>
      </div> 
    );
  }

  renderContent(value) {

    const {
      details
    } = this.props
    return (
      <>
        <p>{ value }</p>
        <p>{ __('銀行帳號：') }<span block="value">{ details.AtmBankNo }</span></p>
        <p>{ __('帳號：') }<span block="value">{ details.AtmNo }</span></p>
        <p>{ __('轉帳金額：') }<span block="value">{ formatPrice(details.Amount) }</span></p>
        <p>{ __('繳費期限：') }<span block="value">{ details.PayEndDate }</span></p>
        <p>{ __('請於交易後確認您的訂單，若發現有任何錯誤，請與商家聯絡。') }</p>
      </>
    )
  }

  render() {
    return (
      <div block="Smilepay" elem="OrderSuccess">
        { this.renderSmilepayPaymentMethod() }
      </div>
    );
  }
}

export default SmilepayatmOrderSuccess;
