import PropTypes from "prop-types";
import { PureComponent } from "react";
import { MINUS_CURRENT_VALUE, PLUS_CURRENT_VALUE } from "./ReviewPagination.config";

import './ReviewPagination.style';

/** @namespace Component/ReviewPagination/Component */
export class ReviewPagination extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    onPageSelect: PropTypes.func.isRequired
  };

  renderPageNumber(pageNumber) {
    const { onPageSelect } = this.props;

    return (
      <li
        key={pageNumber}
        block="ReviewPagination"
        elem="ListItem"
        className={this.getPageClass(pageNumber)}
      >
        <span label={pageNumber} onClick={onPageSelect}>
          {pageNumber}
        </span>
      </li>
    );
  }

  getPageClass(value) {
    let response = "hide";
    const { currentPage } = this.props;
    let minusValue = currentPage - MINUS_CURRENT_VALUE;
    let plusValue = currentPage + PLUS_CURRENT_VALUE;

    if (minusValue <= value && plusValue >= value) {
      response = "show";
    }

    if (value == currentPage) {
      response += " active";
    }

    return response;
  }

  renderPageNumbers() {
    const { currentPage, totalPages } = this.props;

    let pages = [];
    let i;
    let isSetPreIcon = false
    let isSetNextIcon = false
    
    for (i = 1; i <= totalPages; i++) {

      if(!isSetPreIcon && i == 1 && currentPage != 1 ) {
        isSetPreIcon = true
        pages.push(this.renderIcon('Prev', currentPage-1))
      }

      pages.push(this.renderPageNumber(i));

      if(!isSetNextIcon && i == totalPages && totalPages != currentPage) {
        isSetNextIcon = true
        pages.push(this.renderIcon('Next', currentPage+1))
      }
    }

    return pages;
  }

  renderIcon(className, pageNumber) {
    const { onPageSelect } = this.props;
    return (
      <li
        key={className+'_1'}
        block="ReviewPagination"
        elem="ListItem"
        className={className}
      >
        <span block="ReviewPagination" elem="Arrow" onClick={onPageSelect}>{pageNumber}</span>
      </li>
    )
  }

  render() {
    const { isLoading } = this.props;
    return (
      <div className="review-pagination">
        <ul block="ReviewPagination" mods={isLoading}>
          
          {this.renderPageNumbers()}
          
        </ul>
      </div>
    );
  }
}

export default ReviewPagination;
