import PropTypes from "prop-types";
import { PureComponent } from "react";
import Loader from "Component/Loader";

/** @namespace Component/ReviewItemInfo/Component */
export class ReviewItemInfo extends PureComponent {
  static propTypes = {
    // Variables
    comment: PropTypes.object.isRequired
  };

  renderCommentsSection() {
    const {
      comment: { content, created_at },
      formatDate
    } = this.props;

    return (
      <div className="data-grid-cell-content comments-block">
        <div className="comment">
          <div className="comment-wrapper admin-comment">
            <div className="comment-top-col">
              <div className="nickname">{__("Response from Support Team")}</div>
              <div className="comment-date">
                {formatDate(created_at, "MMM DD, YYYY")}
              </div>
            </div>
            <div className="comment-content">{content}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading } = this.props;

    if (isLoading) {
      return <Loader isLoading={isLoading} />;
    }

    return (
      <div className="review-comments">
        {this.renderCommentsSection()}
      </div>
    );
  }
}

export default ReviewItemInfo;
