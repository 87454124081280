import ProductReviewRating from "Component/ProductReviewRating";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { fetchQuery } from "Util/Request";
import ExpandableContent from 'Component/ExpandableContent';
import AheadworkAdvanceReviewQuery from "../../query/AheadworkAdvanceReview.query";

import ContentWrapper from "Component/ContentWrapper";
import "./AheadworkAdvanceReview.style";
import Field from "Component/Field";
import { SELECT_TYPE, TEXTAREA_TYPE } from "Component/Field/Field.config";

import { RECOMMEND_PRODUCT_OPTIONS, FILTER_OPTIONS, CURRENT_PAGE, PAGE_SIZE, TOTAL_PAGE, } from "./AheadworkAdvanceReview.config";

import Form from "Component/Form";
import Loader from "Component/Loader";

import ReviewItemInfo from '../ReviewItemInfo';

import { UPDATE_REVIEW  } from '../../util/Event/Events'
import { isSignedIn } from 'Util/Auth';
import ReviewPagination from '../ReviewPagination';


/** @namespace Component/AheadworkAdvanceReview/Component */
export class AheadworkAdvanceReview extends PureComponent {
  static propTypes = {
    product: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
   // writeReview: PropTypes.bool.isRequired,

    nickname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    advantage: PropTypes.string.isRequired,
    disadvantage: PropTypes.string.isRequired,
    comments: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    sortingOptions: PropTypes.array.isRequired,
    filterOptions: PropTypes.array.isRequired,
    selectedFilterOption: PropTypes.number.isRequired,
    featuredReview: PropTypes.object,
    selectedRatingProductValue: PropTypes.number.isRequired,

    updateProductIdIntoProduct: PropTypes.func.isRequired,
    getRating: PropTypes.func.isRequired,
    writeReviewBtnClick: PropTypes.func.isRequired,
    saveWriteReviewClickError: PropTypes.func.isRequired,
    saveWriteReviewClickSuccess: PropTypes.func.isRequired,
    changeNickname: PropTypes.func.isRequired,
    changeSummary: PropTypes.func.isRequired,
    changeComments: PropTypes.func.isRequired,
    changeDisadvantage: PropTypes.func.isRequired,
    changeAdvantage: PropTypes.func.isRequired,
    changeEmail: PropTypes.func.isRequired,
    updateIsLoading: PropTypes.func.isRequired,
    updateSorting: PropTypes.func.isRequired,
    setFilterOption: PropTypes.func.isRequired,
    setRatingProductValue: PropTypes.func.isRequired
    
  };

  containerFunctions = {
    onPageSelect: this.onPageSelect.bind(this)
  }


  async componentDidMount() {
    let that = this
    var { filters, updateIsLoading } = this.props;
    var { pageSize, currentPage } = this.state;
    await this.requestReviewsData(filters, pageSize, currentPage);
    await this.requestReviewsSummery();
    updateIsLoading(false)

    // Update Review Details
    window.addEventListener(UPDATE_REVIEW, function(event){
      that.requestReviewsData(filters, pageSize, currentPage)
    });
  }

  __construct(props) {
    super.__construct(props);

    this.state = {
      ...this.state,
      reviewsSummery: {},
      reviewsData: [],

      // Page info
      pageSize: PAGE_SIZE,
      currentPage: CURRENT_PAGE,
      totalPage: TOTAL_PAGE
    };
  }

  async requestReviewsData(filters, pageSize, currentPage) {
    const { updateProductIdIntoProduct } = this.props;
    filters = updateProductIdIntoProduct(filters);
    const sortOrder = this.getSortOrderBeforeFetch();
    const { 
      getAheadworksAdvancedReviews: {
        items,
        page_info
      } 
    } = await fetchQuery(
      AheadworkAdvanceReviewQuery.getAheadworksAdvancedReviewsQuery(
        filters,
        sortOrder,
        pageSize,
        currentPage
      ));
    
    this.setState({
      reviewsData: items,
      pageSize: page_info.page_size,
      currentPage: page_info.current_page,
      totalPage: page_info.total_pages
    });
    
  }

  getSortOrderBeforeFetch() {
    const {
      sortingOptions
    } = this.props

    let response = {}

    sortingOptions.forEach(function(value){
      if(value.active){
        return response[value.code] = value.value
      }
    })
    return response
  }

  async requestReviewsSummery() {
    const {
      product: { id }
    } = this.props;
    await fetchQuery(
      AheadworkAdvanceReviewQuery.getAheadworksAdvancedReviewsSummery(id)
    ).then(
      ({ getAheadworksAdvancedReviewsSummery: summeryResponse }) => {
        this.setState({ reviewsSummery: summeryResponse });
      }
    );
  }

  renderReviewForm() {
    const { 
      selectedRecommendedProductValue
    } = this.state;

    const { 
      product, 
      setRecommendedProductValue, 
      saveWriteReviewClickSuccess, 
      saveWriteReviewClickError,
      changeNickname,
      nickname,
      summary,
      changeSummary,
      comments,
      changeComments,
      disadvantage,
      changeDisadvantage,
      advantage,
      changeAdvantage,
      email,
      changeEmail,
      isLoading,
      writeReview,
      selectedRatingProductValue,
      setRatingProductValue
    } = this.props;

    let ratingOptions = JSON.parse(JSON.stringify(FILTER_OPTIONS))
    ratingOptions.shift()

    if (!writeReview) {
      return null
    }

    return (
      <Form
        id="AdvanceReview"
        mix={ { block: 'AdvanceReview', mix:{ block:'form-block' }} }
        onSubmitError={ saveWriteReviewClickError }
        onSubmitSuccess={ saveWriteReviewClickSuccess }
      >
        <Loader isLoading={ isLoading } />
        <div className="aw-ar-wrapper-fieldset">
          <div className="fieldset">
            <div className="field">
              <label className="label">{__("You're reviewing:")}</label>
              <div className="control">
                <span className="admin-field-value">{ product.name }</span>
              </div>
            </div>

            <div className="field _required">
              <div className="control">
                <Field
                  type="text"
                  className="input-text"
                  id="review_nickname"
                  name="review_nickname"
                  label={__("Nickname")}
                  validation={["notEmpty"]}
                  value={ nickname }
                  onChange={ changeNickname }
                />
              </div>
            </div>
            <div className="field _required">
              <div className="control">
                <Field
                  type="text"
                  className="input-text"
                  id="review_email"
                  name="review_email"
                  label={__("Email")}
                  validation={["notEmpty"]}
                  value={ email }
                  onChange={ changeEmail }
                />
              </div>
            </div>

            <div className="field _required">
              <div className="control">
                <Field
                  type="text"
                  className="input-text"
                  id="review_advantages"
                  name="review_advantages"
                  label={__("Advantages")}
                  validation={["notEmpty"]}
                  value={ advantage }
                  onChange={ changeAdvantage }
                />
              </div>
            </div>

            <div className="field _required">
              <div className="control">
                <Field
                  type="text"
                  className="input-text"
                  id="review_disadvantages"
                  name="review_disadvantages"
                  label={__("Disadvantages")}
                  validation={["notEmpty"]}
                  value={ disadvantage }
                  onChange={ changeDisadvantage }
                />
              </div>
            </div>

            <div className="field _required">
              <div className="control">
                <Field
                  type="text"
                  className="input-text"
                  id="review_comments"
                  name="review_comments"
                  label={__("Comment")}
                  validation={["notEmpty"]}
                  value={ comments }
                  onChange={ changeComments }
                />
              </div>
            </div>

            <div className="field _required">
              <div className="control">
                <Field
                  type={TEXTAREA_TYPE}
                  className="input-text"
                  id="review_text"
                  name="review_text"
                  label={__("Review Summary")}
                  validation={["notEmpty"]}
                  placeholder={__("Share your thoughts about the product")}
                  value={ summary }
                  onChange={ changeSummary }
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <div className="option-select-cont">
                  <Field
                    id="review_rating_product"
                    name="review_rating_product"
                    type={ SELECT_TYPE }
                    label={__("Rating")}
                    validation={["notEmpty"]}
                    mix={{ block: "ReviewRatingProduct", elem: "Select" }}
                    selectOptions={ratingOptions}
                    value={selectedRatingProductValue}
                    onChange={setRatingProductValue}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <div className="control">
                <div className="option-select-cont">
                  <Field
                    id="review_recommended_product"
                    name="review_recommended_product"
                    type={ SELECT_TYPE }
                    label={__("Do you recommend this product?")}
                    validation={["notEmpty"]}
                    mix={{ block: "ReviewRecommendedProduct", elem: "Select" }}
                    selectOptions={RECOMMEND_PRODUCT_OPTIONS}
                    value={selectedRecommendedProductValue}
                    onChange={setRecommendedProductValue}
                  />
                </div>
              </div>
            </div>
            {this.renderReviewButton()}
          </div>
        </div>

      </Form>
    );
  }

  renderReviewButton() {

    return (
      <div block="AdvanceReview" elem="StickyButtonWrapper">
          <button
            type="submit"
            block="Button"
            mix={ { block: 'AdvanceReview', elem: 'Button' } }
          >
              { __('Write Review') }
          </button>
      </div>
    );
  }

  renderFilterOptions() {
    const {
      selectedFilterOption,
      setFilterOption
    } = this.props
    return (
      <div className="filter-options">
        <Field
          id="review_recommended_product"
          name="review_recommended_product"
          type={ SELECT_TYPE }
          // label={__("Do you recommend this product?")}
          validation={["notEmpty"]}
          mix={{ block: "ReviewRecommendedProduct", elem: "Select" }}
          selectOptions={FILTER_OPTIONS}
          value={selectedFilterOption}
          onChange={setFilterOption}
        />
      </div>
    );
  }

  renderSorting() {
    const {
      sortingOptions
    } = this.props
    return (
      <div className="review-sorting">
        <div className="form-field-control">
          <div className="label">
            <b>{__("Sort by")}</b>
          </div>
          {sortingOptions.map(option =>
            <div className={`label sort-item ${option.active ? "active": ""}`} key={option.code} onClick={ this._updateSorting.bind(this, option.code) }>
              <span>{ option.lable }</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  _updateSorting(code, event) {
    const {
      updateSorting
    } = this.props
    updateSorting(code)
  }

  renderRating(label, data) {
    return (
      <div className="ratting-value-row">
        <span className="rating-value-label">{__(label)}{__("stars")}</span>
        <div className="rating-bar">
          <div
            className="progress-bar"
            style={{ width: data.percentage_reviews }}
          ></div>
        </div>
        <span className="label rating-value-reviews-count">
          {data.total_reviews}
        </span>
        <span className="label rating-value-reviews-percent">
          {"( " + data.percentage_reviews + "% )"}
        </span>
      </div>
    );
  }

  renderRatingValues() {
    const { reviewsSummery } = this.state;

    if (!reviewsSummery.stars1) {
      return null;
    }

    const {
      reviewsSummery: { stars1, stars2, stars3, stars4, stars5 }
    } = this.state;

    return (
      <div className="column-summary">
        <div className="detailed-summary">
          <div className="rating-values-statistics">
            {this.renderRating(__("5"), stars5)}
            {this.renderRating(__("4"), stars4)}
            {this.renderRating(__("3"), stars3)}
            {this.renderRating(__("2"), stars2)}
            {this.renderRating(__("1"), stars1)}
          </div>
        </div>
      </div>
    );
  }

  renderShowReviewWriteForm() {

    const { writeReviewBtnClick, writeReview } = this.props;

    if (writeReview || !isSignedIn()) {
      return null;
    }

    return (
      <div className="column-summary">
        <div className="aw-ar-write-review-control">
          <button className="Button" elem="Button" onClick={ writeReviewBtnClick }>
            {__("Write Review")}
          </button>
        </div>
      </div>
    );
  }

  renderLoginLink() {

    if(isSignedIn()){
      return null
    }

    return (
      <div className="guest-message">
        {__("Please")}
        <a href={void(0)} className="review-signin" key="account" onClick={ this.loginAccount }>{__("sign in")}</a>
        {__("or")}
        <a href={void(0)} className="review-signin" key="review_account" onClick={ this.loginAccount }>{__("create an account")}</a>
        {__("to write a review.")}
      </div>
    );
  }

  loginAccount(){
    if(document.getElementById('myAccount')) {
      setTimeout(function(){
        document.querySelector('.Header-Button_type_account').click()
      }, 100)
    }
  }

  renderRecommendCustomers() {
    const { reviewsSummery } = this.state;

    if (!reviewsSummery.recommended_customer) {
      return null;
    }

    return (
      <div className="recommend-customers">
        <span className="percent">{reviewsSummery.recommended_customer + "%"}</span>
        <span className="recommend-text">
          {__("of customers recommend this product")}
        </span>
      </div>
    );
  }

  renderCustomerReviews() {
    const { product } = this.props;

    if (!product.aheadworks_advanced_review) {
      return null;
    }

    const {
      product: {
        aheadworks_advanced_review: { aggregated_rating, reviews_count }
      },
      getRating
    } = this.props;

    return (
      <div className="column-summary">
        <div className="brief-summary">
          <div className="aggregated-rating-absolute">
            {getRating(aggregated_rating)}
          </div>
          <div className="reviews-section">
            <div className="not-editable">
              <span className="tooltip-rating">
                {getRating(aggregated_rating) + __(" out of 5 stars")}
              </span>
            </div>
            <div className="aggregated-rating-view">
              <ProductReviewRating summary={aggregated_rating || 0} />
            </div>
            <div className="reviews-count">{reviews_count + __(" Reviews")}</div>
          </div>
        </div>
        {this.renderRecommendCustomers()}
      </div>
    );
  }

  renderCustomerReviewsMain() {
    return (
      <div className="aw-ar-review-summary">
        {this.renderCustomerReviews()}
        {this.renderRatingValues()}
        {this.renderShowReviewWriteForm()}
      </div>
    );
  }

  renderReviewPagination() {
    const {
      isLoading
    } = this.props;

    const {
      currentPage,
      totalPage
    } = this.state;

    return (
      <ReviewPagination 
        isLoading={ isLoading }
        totalPages={ totalPage }
        currentPage= { currentPage }
        { ...this.containerFunctions }
      />
    );
  }

  async onPageSelect(event) {
    const { filters } = this.props;
    const { pageSize } = this.state;
    await this.setState({ currentPage : parseInt(event.target.textContent), isLoading: true })
    const { currentPage } = this.state;
    await this.requestReviewsData(filters, pageSize, currentPage)
  }

  renderFeaturedReview() {
    const {
      featuredReview
    } = this.props;

    if(Object.keys(featuredReview).length == 0) {
      return null
    }

    return (
      <div className="review-list" >
          <div className="review-content">
            <h3 className="featured-review-title">{__("Featured review(s)")}</h3>
          </div>
          <div className="block-content">
            <ReviewItemInfo
              key={ featuredReview.id }
              review={ featuredReview }
            />
          </div>
        </div>
    )
  }

  renderTabs() {

    const {
      reviewsData
    } = this.state

    return (
      <div className="data item content">
        <div className="review-main">
          <h2 className="title">{__("Customer Reviews ")}</h2>
          {this.renderCustomerReviewsMain()}
          {this.renderLoginLink()}
        </div>
        {this.renderReviewForm() }
        { this.renderFeaturedReview() }
        <div className="block-content">
          <div className="data-grid-filters-wrap">
            {this.renderFilterOptions()}
            {this.renderSorting()}
          </div>
          {reviewsData.map(review =>
            <ReviewItemInfo
              key={ review.id }
              review={ review }
            />
          )}
          <div className="aw-ar__review-list-nav">
            <div className="aw-ar__review-list-nav__inner">
              { this.renderReviewPagination() }
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading } = this.props;

    if (isLoading) {
      return (
        <Loader isLoading={ isLoading } />
      );
    }

    return (
      <ContentWrapper
        wrapperMix={{ block: "ProductFeedback", elem: "Wrapper" }}
        label={__("Product tabs")}
      >
        <ExpandableContent
          mix={ { block: 'ProductFeedback', elem: 'Content' } }
          heading={ __('Reviews') }
        >
            {this.renderTabs()}
        </ExpandableContent>
      </ContentWrapper>
    );
  }
}

export default AheadworkAdvanceReview;
