import getStore from 'Util/Store';
import TappayReducer from '../store/Tappay.reducer';

/** @namespace Store/Index/getReducers */
export const getStaticReducers = () => ({
    TappayReducer
});

// eslint-disable-next-line
export const _configureStore = (args, callback, instance) => {
    
    const store = getStore();
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );
    return callback(...args)
}

export default {
    'Component/App/Component': {
        'member-function': {
            configureStore: [
                {
                    position: 101,
                    implementation: _configureStore
                }
            ]
        }
    }
};
