/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import CheckoutShippingDeliveryDate from './CheckoutShippingDeliveryDate.component';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, fetchQuery } from 'Util/Request';
import CartQuery from 'Query/Cart.query'
import { AMASTY_DELIVERY_DATE_UPDATE, AMASTY_DELIVERY_DATE_SET, AMASTY_DELIVERY_DATE_SET_RESPONSE, AMASTY_DELIVERY_CITY_UPDATE } from '../../util/Event/Events'


import DeliveryDateQuery from '../../query/DeliveryDate.query'
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { isSignedIn } from 'Util/Auth';
import moment from 'moment';

export const mapStateToProps = state => ({
    config: state.ConfigReducer,
    cart: state.CartReducer,
    state: state,
    amastyDelivery: state.CartReducer.cartTotals.amasty_delivery
});

/** @namespace Component/CheckoutShippingDeliveryDate/Container */
export class CheckoutShippingDeliveryDateContainer extends PureComponent {

    static propTypes = {
        config: PropTypes.object.isRequired,
        cart: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        amastyDelivery: PropTypes.object.isRequired
    };

    __construct(props) {
        super.__construct(props);
        if (props.amastyDelivery == undefined) {
            history.push({
                pathname: appendWithStoreCode('/')
            });
            history.go(0)
            return;
        }

        var currentDate = moment()
        if (props.amastyDelivery.delivery_date) {
            currentDate = this.getTime(props.amastyDelivery.delivery_date)
        }


        this.state = {
            isLoading: false,
            dateValue: this.getTime(currentDate),
            dateValueError: null,
            commentValue: props.amastyDelivery.delivery_comment,
            commentValueError: null,
            selectedTimeValue: props.amastyDelivery.delivery_time_id,
            selectedTimeValueError: null,
            disabledDates: [],
            maximumDay: this.getTime(moment()),
            minimumDay: this.getTime(moment())
        };

    }

    async componentDidMount() {
        let that = this
        window.addEventListener(AMASTY_DELIVERY_DATE_SET, function (event) {
            that.setDeliveryDateIntoCart()
        });

        window.addEventListener(AMASTY_DELIVERY_CITY_UPDATE, async function (event) {
            await that.manageCityUpdateEvent(event.detail)
        });

        // Date Picker
        await this.getDatePicker();

        // Update First Availabel Date
        await this.setFirstAvailabelDate()

        await this.updateDeliveryDateValues()
    }

    containerFunctions = {
        getTimeOptions: this.getTimeOptions.bind(this),
        setTimeValue: this.setTimeValue.bind(this),
        changeDateValue: this.changeDateValue.bind(this),
        updateCommentValue: this.updateCommentValue.bind(this),
        setDeliveryDateIntoCart: this.setDeliveryDateIntoCart.bind(this)
    };

    async manageCityUpdateEvent(cityId) {
        if (cityId == null || cityId == undefined || cityId == 0) {
            return
        }
        const {
            disabledDates
        } = this.state
        this.setState({ isLoading: true })
        const { getDisableDayPerCity } = await fetchQuery(DeliveryDateQuery.getDisableDayPerCity(cityId))
        const mergeDisableDays = disabledDates.concat(getDisableDayPerCity)
        await this.setState({ disabledDates: mergeDisableDays })
        await this.setState({ isLoading: false })
        await this.setFirstAvailabelDate()
        await this.updateDeliveryDateValues()
    }

    getDatePicker() {
        const {
            config: {
                amasty_delivery_date: {
                    general: {
                        disabled_days,
                        min_days,
                        max_days,
                        dintervals
                    }
                }
            }
        } = this.props


        // Update Current Value
        const minmumDay = this.addDay(this.getTime(moment()), min_days)
        const maximumDay = this.addDay(this.getTime(moment()), max_days)

        this.setState({ minimumDay: minmumDay })
        this.setState({ maximumDay: maximumDay })

        // Generate Disable date
        var _disableDays = []
        if (disabled_days.length > 0) {
            for (var d = this.getTime(minmumDay); d <= this.getTime(maximumDay); d = this.addDay(d, 0)) {
                if (disabled_days.includes(moment(d).day())) {
                    _disableDays.push(parseInt(this.getTime(d)))
                }
            }
        }

        var _disabledDate = []
        if (dintervals.length > 0) {
            _disabledDate = this.makeFormatDate(dintervals);
        }

        const _mergeDisableDays = _disabledDate.concat(_disableDays)
        this.setState({ disabledDates: _mergeDisableDays })
    }

    renderFormatMonth(month) {
        if (month < 10) {
            return '0' + month
        }
        else return month
    }

    renderFormatDay(day) {
        if (day < 10) {
            return '0' + day
        }
        else return day
    }

    makeFormatDate(data) {

        let _disabledDates = [];
        if (data.length) {
            data.map((value) => {

                let { from: { year: startYear, month: startMonth, day: startDay } } = value;
                startMonth = this.renderFormatMonth(startMonth);
                startDay = this.renderFormatDay(startDay);


                let { to: { year: endYear, month: endMonth, day: endDay } } = value;
                endMonth = this.renderFormatMonth(endMonth);
                endDay = this.renderFormatDay(endDay);


                let startdate = startYear.toString() + startMonth.toString() + startDay.toString();
                let enddate = endYear.toString() + endMonth.toString() + endDay.toString();

                for (var d = this.getTime(startdate); d <= this.getTime(enddate); d = this.addDay(d, 0)) {

                    if (!_disabledDates.includes(parseInt(this.getTime(d)))) {
                        _disabledDates.push(parseInt(this.getTime(d)))
                    }
                }
            })
        }

        return _disabledDates
    }

    setFirstAvailabelDate() {
        const {
            disabledDates,
            dateValue
        } = this.state

        if (this.props.amastyDelivery) {
            const {
                config: {
                    amasty_delivery_date: {
                        general: {
                            max_days,
                            min_days
                        }
                    }
                },
                amastyDelivery: {
                    delivery_date
                }
            } = this.props

            const minmumDay = this.addDay(this.getTime(new Date()), min_days)
            const maximumDay = this.addDay(this.getTime(new Date()), max_days)

            if (disabledDates.length > 0) {
                for (var d = this.getTime(minmumDay); d <= this.getTime(maximumDay); d = this.addDay(d, 0)) {
                    if (!disabledDates.includes(parseInt(this.getTime(d)))) {
                        this.setState({ minimumDay: this.getTime(d) })
                        if (!delivery_date || disabledDates.includes(parseInt(dateValue))) {
                            this.setState({ dateValue: this.getTime(d) })
                        }

                        break
                    }
                }
            }
        }

    }

    addDay(date, day) {
        return this.getTime(moment(date, 'YYYY-MM-DD').add('days', parseInt(day + 1)))
    }

    checkValidationOfDeliveryDate() {

        var response = true
        this.setState({
            dateValueError: null,
            commentValueError: null,
            selectedTimeValueError: null
        })

        const {
            config: {
                amasty_delivery_date: {
                    general: {
                        enable
                    },
                    time,
                    comment,
                    date
                }
            }
        } = this.props

        const {
            dateValue,
            commentValue,
            selectedTimeValue
        } = this.state

        if (!enable) {
            return response
        }

        // Check Validation
        if (enable && date.required && dateValue == "") {
            response = false
            this.setState({
                dateValueError: __('This field is required!')
            })
        }
        if (time.required && selectedTimeValue == 0) {
            response = false
            this.setState({
                selectedTimeValueError: __('This field is required!')
            })
        }

        if (comment.required && commentValue == "") {
            response = false
            this.setState({
                commentValueError: __('This field is required!')
            })
        }

        return response
    }

    getTimeOptions(tintervals) {

        let options = []

        if (tintervals.length > 0) {
            tintervals.forEach(function (items, key) {
                options[key] = {
                    id: key,
                    name: items.label,
                    value: parseInt(items.value),
                    label: items.label
                }
            })
        }

        return options;
    }

    setTimeValue(value) {
        this.setState({ selectedTimeValue: parseInt(value) });
    }

    changeDateValue(value) {
        this.setState({ dateValue: this.getTime(value) });
    }

    getTime(date, format = 'YYYYMMDD') {
        return moment(date).format(format)
    }

    updateCommentValue(value) {
        this.setState({ commentValue: value });
    }

    async setDeliveryDateIntoCart() {

        const {
            dateValue,
            commentValue,
            selectedTimeValue
        } = this.state

        const validation = await this.checkValidationOfDeliveryDate()

        if (validation) {

            this.setState({
                isLoading: true
            })

            await fetchMutation(DeliveryDateQuery.setAmastyDeliveryDate(
                !isSignedIn() && getGuestQuoteId(),
                moment(dateValue).format('YYYY-MM-DD'),
                selectedTimeValue,
                commentValue
            ))

            const cart = await fetchQuery(CartQuery.getCartQuery(!isSignedIn() && getGuestQuoteId()))

            window.dispatchEvent(new CustomEvent(AMASTY_DELIVERY_DATE_UPDATE, { detail: cart }));

            const {
                cartData: {
                    amasty_delivery: {
                        delivery_date,
                        delivery_comment,
                        delivery_time_id
                    }
                }
            } = cart

            this.setState({
                dateValue: delivery_date,
                commentValue: delivery_comment,
                selectedTimeValue: parseInt(delivery_time_id),
                isLoading: false
            })
        }

        await window.dispatchEvent(new CustomEvent(AMASTY_DELIVERY_DATE_SET_RESPONSE, { detail: validation }));
    }

    updateDeliveryDateValues() {
        const {
            amastyDelivery
        } = this.props

        if (amastyDelivery != undefined) {
            this.setState({
                commentValue: amastyDelivery.delivery_comment,
                selectedTimeValue: parseInt(amastyDelivery.delivery_time_id)
            })
        }
    }

    render() {

        return (
            <CheckoutShippingDeliveryDate
                {...this.props}
                {...this.containerFunctions}
                {...this.state}
            />
        );
    }
}

export default connect(
    mapStateToProps
)(
    CheckoutShippingDeliveryDateContainer,
);
