/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import Form from 'Component/Form';
import Loader from 'Component/Loader';

import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';

import CheckoutShippingDeliveryDate from '../component/CheckoutShippingDeliveryDate'

export class CheckoutShippingComponentPlugin {

    renderActions = (args, callback, instance) => {
        const {
            onShippingSuccess,
            onShippingError,
            isLoading
        } = instance.props;
    
        return (
            <Form
              id={ SHIPPING_STEP }
              mix={ { block: 'CheckoutShipping' } }
              onSubmitError={ onShippingError }
              onSubmitSuccess={ onShippingSuccess }
            >
                { instance.renderAddressBook() }
                <div>
                    <Loader isLoading={ isLoading } />
                    { instance.renderDelivery() }
                    { this.renderDeliveryDate() }
                    { instance.renderActions() }
                </div>
            </Form>
        );
    };

    renderDeliveryDate()
    {
        return (
            <CheckoutShippingDeliveryDate />
        );
    }

}

export const { renderActions } = new CheckoutShippingComponentPlugin();

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            render: {
                position: 150,
                implementation: renderActions
            }
        }
    }
};