export const ADVANCE_REVIEW = 'feedback';
import AheadworkAdvanceReview from '../component/AheadworkAdvanceReview';

export class ProductPageComponentPlugin {

    tabMap = (originalMember) => ({
        ...originalMember,
        [ADVANCE_REVIEW]: {
            name: __('Reviews'),
            shouldTabRender: () => true,
            render: (key) => this.renderAheadworkAdvanceReview(key)
        }
    });

    renderAheadworkAdvanceReview(key) {

        return (
            <AheadworkAdvanceReview
              key={ key }
            />
        );
    }
}


export const { tabMap, propTypes } = new ProductPageComponentPlugin();

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: [
                {
                    position: 101,
                    implementation: tabMap
                }
            ]
        }
    }
};