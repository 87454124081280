/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Field } from 'Util/Query';

const _getStoreConfigFieldsAction = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('payment_tappay_active'),
        new Field('payment_tappay_app_id'),
        new Field('payment_tappay_app_key'),
        new Field('payment_tappay_server_type'),
        new Field('payment_tappay_tappay_google_pay_active'),
        new Field('payment_tappay_tappay_google_pay_merchant_id'),
        new Field('payment_tappay_tappay_google_pay_merchant_name'),
        new Field('payment_tappay_tappay_google_pay_require_email'),
        new Field('payment_tappay_tappay_google_pay_require_shipping_address'),
        new Field('payment_tappay_tappay_google_pay_require_billing_address'),
        new Field('payment_tappay_tappay_google_pay_billing_address_format'),
        new Field('payment_tappay_tappay_google_pay_allow_prepaid_cards'),
        new Field('payment_tappay_tappay_google_pay_allowed_country'),
        new Field('payment_tappay_tappay_google_pay_require_phone_number')
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: {
                position: 100,
                implementation: _getStoreConfigFieldsAction
            }
        }
    }
};