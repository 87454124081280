/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import DeliveryDateSummary from './DeliveryDateSummary.component';
import { AMASTY_DELIVERY_DATE_UPDATE } from '../../util/Event/Events'

export const mapStateToProps = state => ({
    amastyDelivery: state.CartReducer.cartTotals.amasty_delivery
});

/** @namespace Component/DeliveryDateSummaryContainer/Container */
export class DeliveryDateSummaryContainer extends PureComponent {

    static propTypes = {
        amastyDelivery: PropTypes.object.isRequired
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            amastyDelivery: props.amastyDelivery
        };
    }

    componentDidMount()
    {
        let that = this
        window.addEventListener(AMASTY_DELIVERY_DATE_UPDATE, function(event){
            that.setState({
                amastyDelivery: event.detail.cartData.amasty_delivery
            })
        });
    }



    render() {
        const {
            amastyDelivery
        } = this.state

        return (
            <DeliveryDateSummary
                amastyDelivery= { amastyDelivery }
            />
        );
    }
}

export default connect(
    mapStateToProps
)(
    DeliveryDateSummaryContainer,
);
