import { PureComponent } from "react";
import PropTypes from "prop-types";
import ReviewPagination from "./ReviewPagination.component";

/** @namespace Component/ReviewPaginationContainer/Container */
export class ReviewPaginationContainer extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    onPageSelect: PropTypes.func.isRequired
  };

  __construct(props) {
    super.__construct(props);
  }

  render() {
    return <ReviewPagination {...this.props} />;
  }
}

export default ReviewPaginationContainer;
