/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import getStore from 'Util/Store';
import { AMASTY_DELIVERY_DATE_SET, AMASTY_DELIVERY_DATE_SET_RESPONSE } from '../util/Event/Events'

export class CheckoutContainerPlugin {

    beforeOnShippingSuccessActions = async (args, callback, instance) => {

        const state = getStore().getState();

        if(!state.ConfigReducer.amasty_delivery_date.general.enable) {
            return callback(...args)
        }


        window.addEventListener(AMASTY_DELIVERY_DATE_SET_RESPONSE, function(event){
            if(event.detail){
                return callback(...args)
            }
        }, false);

        await window.dispatchEvent(new CustomEvent(AMASTY_DELIVERY_DATE_SET));
    };

}

export const { afterSaveAddressInformationActions, beforeOnShippingSuccessActions } = new CheckoutContainerPlugin();

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: {
                position: 100,
                implementation: beforeOnShippingSuccessActions
            }
        }
    }
};