/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import OrderDeliveryDate from '../component/OrderDeliveryDate';

export class MyAccountOrderPopupComponentPlugin {

    renderContentAction = (args, callback, instance) => {

        const { order: { order_products } } = instance.props;

        if (!order_products) {
            return null;
        }

        return (
            <>
                { instance.renderBaseInfo() }
                { instance.renderPayment() }
                { <OrderDeliveryDate {...instance.props } /> }
                { instance.renderShipping() }
                { instance.renderProducts() }
                { instance.renderTotals() }
            </>
        );
    };

}

export const { renderContentAction } = new MyAccountOrderPopupComponentPlugin();

export default {
    'Component/MyAccountOrderPopup/Component': {
        'member-function': {
            renderContent: {
                position: 100,
                implementation: renderContentAction
            }
        }
    }
};