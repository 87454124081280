/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import getStore from 'Util/Store';
import { AMASTY_DELIVERY_CITY_UPDATE, AMASTY_DELIVERY_RENDER_FIELD } from '../util/Event/Events'
export const DeliveryDateDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/Amasty/DeliveryDate.dispatcher'
);

export class CheckoutAddressFormPlugin {

    renderfieldMap = (args, callback, instance) => {

        const {
            city_id
        } = callback.state

        var original = { ...args }

        const state = getStore().getState();

        // Check amasty_delivery_date enable or not
        if (state.ConfigReducer.amasty_delivery_date.general.enable) {

            // Manage City
            let deliverySurb = 'AmDeliverySurbDisable'
            var selectedCity = 0;
            var cityOptions = []
            if (state.DeliveryDateReducer.cities.length > 0) {
                cityOptions = state.DeliveryDateReducer.cities;
            }

            // Manage Suburb
            var selectedSuburb = 0;
            var suburbOptions = []
            if (state.DeliveryDateReducer.suburb.length > 0 && city_id > 0) {
                deliverySurb = ''
                suburbOptions = state.DeliveryDateReducer.suburb
                selectedSuburb = state.DeliveryDateReducer.suburb[0].value
            }

            // Remove Old city format
            const customFields = {

                country_id: {
                    label: args.country_id.label,
                    onChange: args.country_id.onChange,
                    selectOptions: args.country_id.selectOptions,
                    type: args.country_id.type,
                    validation: args.country_id.validation,
                    value: args.country_id.value,
                    mix: { block: 'AmDeliveryDateCountryIdDisable' }
                },
                city: {
                    label: args.city.label,
                    onChange: args.city.onChange,
                    value: "-",
                    mix: { block: 'AmDeliveryDateCityDisable' }
                },
                city_id: {
                    label: __('City'),
                    validation: ['notEmpty'],
                    type: 'select',
                    value: selectedCity,
                    onChange: (value) => this.onCityChange(value, callback),
                    selectOptions: cityOptions
                },
                surb_id: {
                    label: __('Surb'),
                    type: 'select',
                    value: selectedSuburb,
                    onChange: (value) => callback.onChange('surb_id', value),
                    selectOptions: suburbOptions,
                    mix: { block: deliverySurb }
                }

            }

            original = { ...args, ...customFields }
        }

        return original
    };

    // Manage City Change
    onCityChange = async (cityId, callback) => {

        const {
            getMagentovnAdvancedCitySuburb
        } = callback.props

        if (cityId > 0) {
            await getMagentovnAdvancedCitySuburb(parseInt(cityId))

            await window.dispatchEvent(new CustomEvent(AMASTY_DELIVERY_CITY_UPDATE, { detail: parseInt(cityId) }));
        }


        await callback.setState({ city_id: parseInt(cityId) })
    }

    __construct = (args, callback, instance) => {

        const state = getStore().getState();

        // Check amasty_delivery_date enable or not
        if (state.ConfigReducer.amasty_delivery_date.general.enable) {

            const {
                getMagentovnAdvancedCities
            } = instance.props

            getMagentovnAdvancedCities()

            setTimeout(function () {
                instance.setState({ city: '-' })
            }, 5000)
        }

        return callback(...args)
    }

}

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const original = callback(...args);
    const state = getStore().getState();

    // Check amasty_delivery_date enable or not
    if (!state.ConfigReducer.amasty_delivery_date.general.enable) {
        return {
            ...original
        }
    }

    return {
        ...original,
        getMagentovnAdvancedCities: () => DeliveryDateDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getMagentovnAdvancedCities(dispatch)
        ),
        getMagentovnAdvancedCitySuburb: (cityId) => DeliveryDateDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getMagentovnAdvancedCitySuburb(dispatch, cityId)
        )
    };
};

export const { renderfieldMap, __construct } = new CheckoutAddressFormPlugin();

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            fieldMap: {
                position: 150,
                implementation: renderfieldMap
            },
            __construct: {
                position: 200,
                implementation: __construct
            }
        }
    },
    'Component/CheckoutAddressForm/Container/mapDispatchToProps': {
        function: [mapDispatchToProps]
    }
};