import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
export const CHANGE_STORE_CODE = 'change_store_code'

export const StoreSwitcherDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/StoreSwitcher/StoreSwitcher.dispatcher'
);

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;
    return {
        ...callback(...args),
        availableStoresList: state.StoreSwitcherReducer.availableStores
    };
};


const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const original = callback(...args);
    return {
        ...original,
        getAvailableStoreListField: () => StoreSwitcherDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getStoreListField(dispatch))
    };
};

export class StoreSwitcherPlugin {

    componentDidMount = async (args, callback, instance) => {
        const { getAvailableStoreListField } = instance.props
        await getAvailableStoreListField();
        return callback(...args)
    }

    componentDidUpdate(args, callback, instance) {

        const { currentStoreCode } = instance.props;
        const { storeLabel, storeList } = instance.state;
        const newStoreCode = BrowserDatabase.getItem(CHANGE_STORE_CODE);

        if (currentStoreCode && (!storeLabel || (newStoreCode !== currentStoreCode))) {
            if(newStoreCode){
                instance.getCurrentLabel(newStoreCode);
            }else{
                instance.getCurrentLabel(currentStoreCode);
            }
        }
    }


    _getStoreList = (args, callback, instance) => {
        const { availableStoresList } = instance.props
        instance.setState({
            storeList: this._formatStoreList(availableStoresList)
        })
    }

    _formatStoreList(availableStores) {
        return availableStores.reduce((acc, {
            store_name, code, base_url, base_link_url
        }) => {
            return [
                ...acc,
                {
                    id: `store_${code}`,
                    value: code,
                    storeUrl: base_url,
                    storeLinkUrl: base_link_url,
                    label: store_name
                }
            ];
        }, []);
    }

}

const _handleStoreSelect = (args, callback, instance) =>{
    const storeCode = args[0]
    BrowserDatabase.setItem(storeCode, CHANGE_STORE_CODE, ONE_MONTH_IN_SECONDS);
    return callback(...args)
}

export const { _getStoreList, componentDidMount, componentDidUpdate } = new StoreSwitcherPlugin();

export default {
    'Component/StoreSwitcher/Container': {
        'member-function': {
            _getStoreList: {
                position: 100,
                implementation: _getStoreList
            },
            componentDidMount: {
                position: 100,
                implementation: componentDidMount
            },
            componentDidUpdate: {
                position: 100,
                implementation: componentDidUpdate
            },
            _handleStoreSelect: {
                position: 100,
                implementation: _handleStoreSelect
            }
        }
    },
    'Component/StoreSwitcher/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/StoreSwitcher/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
