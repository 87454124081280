/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Loader from 'Component/Loader';
import { DeviceType } from 'Type/Device';
import ProductReviewRating from 'Component/ProductReviewRating';
import Form from "Component/Form";
import Field from 'Component/Field';


import './AdvanceProductReview.style';
import ReviewPagination from '../ReviewPagination';

/** @namespace Component/AdvanceProductReview/Component */
export class AdvanceProductReview extends PureComponent {

    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        reviews: PropTypes.array.isRequired,
        getStatus: PropTypes.func.isRequired,
        getCreatedValue: PropTypes.func.isRequired,
        device: DeviceType,
        isNewCommentEmailEnabled: PropTypes.bool.isRequired,
        changeIsNewCommentEmailEnabled: PropTypes.func.isRequired,
        isReviewReminderEmailEnabled: PropTypes.bool.isRequired,
        changeIsReviewReminderEmailEnabled: PropTypes.func.isRequired,
        isReviewApprovedEmailEnabled: PropTypes.bool.isRequired,
        changeIsReviewApprovedEmailEnabled: PropTypes.func.isRequired,
        updateNotification: PropTypes.func.isRequired,
        ratingSorting: PropTypes.object.isRequired,
        createdSorting: PropTypes.object.isRequired,
        updateSortIcon: PropTypes.func.isRequired,
        onPageSelect: PropTypes.func.isRequired,
        pageInfo: PropTypes.object.isRequired,
        goToProductReviewPage: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);
    }

    renderNoProductReview() {
        const { device, reviews } = this.props;

        if(!reviews) {
            return (
                <tr block="MyAccountProductReview" elem="NoReview">
                    { /* eslint-disable-next-line no-magic-numbers */ }
                    <td colSpan={ device.isMobile ? 3 : 5 }>{ __('You have no Review.') }</td>
                </tr>
            );
        }
    }

    renderSortIcon(data) {
        const {
            updateSortIcon
        } = this.props
        if(data.value == 'DESC') {
            return (<div key={data.code} id={data.code} block="Sortable" elem="Descend" onClick={updateSortIcon}><span block="SortableIcon"></span></div>);
        }else{
            return (<div key={data.code} id={data.code} block="Sortable" elem="Ascend" onClick={updateSortIcon}><span block="SortableIcon"></span></div>);
        }
    }

    renderProductReviewHeadingRow() {

        const {
            createdSorting
        } = this.props

        let renderCreatedSorting = this.renderSortIcon(createdSorting)

        return (
            <tr>
                <th>{ __('Created') }{ renderCreatedSorting }</th>
                <th>{ __('Product Name') }</th>
                <th block="hidden-mobile">{ __('Status') }</th>
                <th>{ __('Rating') }</th>
                <th block="hidden-mobile">
                    { __('Summary') }
                </th>
            </tr>
        );
    }

    renderProductReviewRows() {
        const {
            reviews
        }= this.props

        if(reviews.length) {
            let rows = [];
            reviews.map((value) => {
                rows.push(this.renderProductReviewRow(value));
            })
            return rows
        }

        return ( this.renderNoProductReview() )
    }

    renderProductReviewRow(review) {
        const {
            getStatus,
            getCreatedValue,
            goToProductReviewPage
        }= this.props
        return(
            <tr block="MyAccountProductReviewTableRow" key={review.id}>
                <td block="hidden-mobile">{ getCreatedValue(review.created_at) }</td>
                <td><span block="ProductReview" elem="Name" onClick={goToProductReviewPage.bind(this,review.product_url )}>{ review.product_name }</span></td>
                <td>{ getStatus(review.status) }</td>
                <td>
                    <span block="ProductReview" elem="Rating">
                        <ProductReviewRating summary={ review.rating } />
                    </span>
                </td>
                <td block="hidden-mobile">{ review.summary }</td>
            </tr>
        );
    }

    renderTable() {
        return (
            <table block="MyAccountProductReview" elem="Table">
                <thead>
                    { this.renderProductReviewHeadingRow() }
                </thead>
                <tbody>
                    { this.renderProductReviewRows() }
                </tbody>
            </table>
        );
    }

    renderReviewPagination() {
        const {
          isLoading,
          pageInfo: {
            current_page,
            total_pages
          },
          onPageSelect
        } = this.props;
    
        return (
          <ReviewPagination 
            isLoading={ isLoading }
            totalPages={ total_pages }
            currentPage= { current_page }
            onPageSelect = { onPageSelect }
          />
        );
      }

    renderReviewNotificationsActions() {
        return (
            <div block="ProductReview" elem="Actions">
                <button block="Button" mix={ { block: 'Button', elem: 'Save' } }>
                    { __('Save') }
                </button>
            </div>
        );
    }

    renderReviewNotifications() {

        const {
            isNewCommentEmailEnabled,
            changeIsNewCommentEmailEnabled,
            isReviewReminderEmailEnabled,
            changeIsReviewReminderEmailEnabled,
            isReviewApprovedEmailEnabled,
            changeIsReviewApprovedEmailEnabled,
            updateNotification
        } = this.props

        return (
            <div block="ProductReview" elem="Notification">
                <div block="Block" elem="Title">
                    { __('Notifications') }
                </div>
                <div block="Block" elem="Content">
                    <Form block="ProductReviewNotification" elem="Form" onSubmitSuccess={updateNotification}>
                        <Field
                        type="checkbox"
                        label={ __('Remind me to write a review after a purchase') }
                        id="remind-review"
                        name="remind-review"
                        value={ isReviewReminderEmailEnabled }
                        checked={ isReviewReminderEmailEnabled }
                        onChange= { changeIsReviewReminderEmailEnabled }
                        />
                        <Field
                        type="checkbox"
                        label={ __('Notify me when a review was approved') }
                        id="notify-approved"
                        name="notify-approved"
                        value={ isReviewApprovedEmailEnabled }
                        checked={ isReviewApprovedEmailEnabled }
                        onChange= { changeIsReviewApprovedEmailEnabled }
                        />
                        <Field
                        type="checkbox"
                        value="notify-review"
                        label={ __('Notify me about a new comment on my review') }
                        id="notify-review"
                        name="notify-review"
                        value={ isNewCommentEmailEnabled }
                        checked={ isNewCommentEmailEnabled }
                        onChange= { changeIsNewCommentEmailEnabled }
                        />
                         { this.renderReviewNotificationsActions() }
                    </Form>
                </div>
            </div>
        );
    }

    render() {
        const {
            isLoading
        } = this.props
        return (
            <div block="MyAccountProductReview">
                <Loader isLoading={isLoading} />
                { this.renderReviewNotifications() }
                { this.renderTable() }
                { this.renderReviewPagination() }
            </div>
        );
    }
}

export default AdvanceProductReview;
