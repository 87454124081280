
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import { GET_TRACKINGINFORMATION } from './ShippingTrackPage.action';


/** @namespace Store/ShippingTrackPage/Reducer/getInitialState */
export const getInitialState = () => ({
    trackingInformation: [],
});


/** @namespace Store/ShippingTrackPage/Reducer */
export const ShippingTrackPageReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case GET_TRACKINGINFORMATION:
            const { trackingInformation } = action;
            return {
                ...state,
                trackingInformation
            };

        default:
            return state;
    }
};

export default ShippingTrackPageReducer;