const _dataPairArray = (args, callback, instance) => {

    const hideData = ['city', 'country', 'region']

    const {
        address:{
            advance_city
        }
    } = callback.props

    var original = []

    args.forEach(function(value){
        if(hideData.indexOf(value.key) == -1){
            original.push(value)
        }
    })

    const customFields = [
        {
            key: 'city_label',
            label: __('City'),
            source: advance_city
        },
        {
            key: 'suburb',
            label: __('Suburb'),
            source: advance_city
        }
    ]

    original = [ ...original, ...customFields]

    return original
}

export default {
    'Component/MyAccountAddressTable/Component': {
        'member-function': {
            dataPairArray: {
                position: 100,
                implementation: _dataPairArray
            }
        }
    }
};