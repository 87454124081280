import ProductReviewRating from 'Component/ProductReviewRating';

export const afterRenderReviews = (args, callback, instance) => {

    const {
        product
    } = instance.props;

    if (product.aheadworks_advanced_review) {

        const {
            product: {
                aheadworks_advanced_review: {
                    aggregated_rating
                } = {}
            },
            layout
        } = instance.props;

        if (!aggregated_rating) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="Reviews"
              mods={ { layout } }
            >
                <ProductReviewRating summary={ aggregated_rating || 0 } />
            </div>
        );
    }

    return null;
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderReviews: afterRenderReviews
        }
    }
};