import { PureComponent } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { showNotification } from 'Store/Notification/Notification.action';
import AheadworkAdvanceReview from "./AheadworkAdvanceReview.component";
import {
  FILTERS,
  RECOMMANDED_PRODUCT_CODE,
  AUTHOR_TYPE_GUEST,
  AUTHOR_TYPE_CUSTOMER,
  IS_VERIFIED_BUYER_YES,
  IS_VERIFIED_BUYER_NO,
  SORTING_OPTIONS,
  FILTER_OPTIONS,
  CURRENT_PAGE
} from "./AheadworkAdvanceReview.config";

import { fetchMutation, fetchQuery } from "Util/Request";
import AheadworkAdvanceReviewQuery from "../../query/AheadworkAdvanceReview.query";
import { isSignedIn } from "Util/Auth";
import { UPDATE_REVIEW } from "../../util/Event/Events";

export const mapStateToProps = state => ({
  product: state.ProductReducer.product,
  config: state.ConfigReducer
});

/** @namespace Component/CheckoutBilling/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  showErrorNotification: (message) => dispatch(showNotification('error', message)),
  showSuccessNotification: (message) => dispatch(showNotification('success', message))
});

/** @namespace Component/AheadworkAdvanceReview/Container */
export class AheadworkAdvanceReviewContainer extends PureComponent {
  static propTypes = {
    product: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
    showSuccessNotification: PropTypes.func.isRequired
  };

  static defaultProps = {
    filters: FILTERS
  };

  __construct(props) {
    super.__construct(props);

    this.state = {
      isLoading: true,
      selectedRecommendedProductValue: RECOMMANDED_PRODUCT_CODE,
      writeReview: false,
      selectedFilterOption: 1000,
      selectedRatingProductValue: 100,

      featuredReview: {},

      nickname: "",
      email: "",
      advantage: "",
      disadvantage: "",
      comments: "",
      summary: "",
      status: "",
      shared_store_ids: "",
      rating: "",
      product_id: "",
      content: "",
      author_type: "",
      is_verified_buyer: IS_VERIFIED_BUYER_NO,

      sortingOptions: SORTING_OPTIONS,
      filterOptions: FILTER_OPTIONS
    };
  }

  componentDidMount() {
    const {
      product: { id }
    } = this.props;
    this.setState({ product_id: id });

    // Update Author Type
    this.updateAuthorType();

    // Get featured review
    this.getFeaturedReview();
  }

  async getFeaturedReview() {

    let _featuredReview = {}

    const {
      product: { id }
    } = this.props;

    const filters = {
      product_id: {
        eq: id
      },
      is_featured: {
        eq: true
      }
    };

    const sort = {
        id: 'DESC'
    };

    const {
      getAheadworksAdvancedReviews: { items }
    } = await fetchQuery(
      AheadworkAdvanceReviewQuery.getAheadworksAdvancedReviewsQuery(
        filters,
        sort,
        1,
        CURRENT_PAGE
    ));

    if(items.length > 0){
        _featuredReview = items[0]
    }

    this.setState({ featuredReview: _featuredReview });
  }

  async setFilterOption(selectedValue) {
    let that = this;
    var { filterOptions } = this.state;
    filterOptions.forEach(function(value, key) {
      filterOptions[key].active = false;
      if (parseInt(selectedValue) == value.value) {
        filterOptions[key].active = true;
        that.setState({ selectedFilterOption: value.value });
      }
    });
    await this.setState({ filterOptions: filterOptions, isLoading: true });
    await window.dispatchEvent(new CustomEvent(UPDATE_REVIEW));
    await this.setState({ isLoading: false });
  }

  updateAuthorType() {
    this.setState({ author_type: AUTHOR_TYPE_GUEST });
    if (isSignedIn()) {
      this.setState({ author_type: AUTHOR_TYPE_CUSTOMER });
      this.setState({ is_verified_buyer: IS_VERIFIED_BUYER_YES });
    }
  }

  containerFunctions = {
    updateProductIdIntoProduct: this.updateProductIdIntoProduct.bind(this),
    getRating: this.getRating.bind(this),
    setRecommendedProductValue: this.setRecommendedProductValue.bind(this),
    writeReviewBtnClick: this.writeReviewBtnClick.bind(this),
    saveWriteReviewClickError: this.saveWriteReviewClickError.bind(this),
    saveWriteReviewClickSuccess: this.saveWriteReviewClickSuccess.bind(this),
    changeNickname: this.changeNickname.bind(this),
    changeSummary: this.changeSummary.bind(this),
    changeComments: this.changeComments.bind(this),
    changeDisadvantage: this.changeDisadvantage.bind(this),
    changeAdvantage: this.changeAdvantage.bind(this),
    changeEmail: this.changeEmail.bind(this),
    updateIsLoading: this.updateIsLoading.bind(this),
    updateSorting: this.updateSorting.bind(this),
    setFilterOption: this.setFilterOption.bind(this),
    setRatingProductValue: this.setRatingProductValue.bind(this)
  };

  writeReviewBtnClick(value) {
    this.setState({ writeReview: value });
  }

  updateIsLoading(value) {
    this.setState({ isLoading: value });
  }

  setRatingProductValue(value) {
    this.setState({ selectedRatingProductValue: parseInt(value) });
  }

  async updateSorting(code) {
    var { sortingOptions } = this.state;

    sortingOptions.forEach(function(value, key) {
      sortingOptions[key].active = false;
      if (value.code == code) {
        sortingOptions[key].active = true;
      }
    });

    await this.setState({ sortingOptions: sortingOptions, isLoading: true });
    await window.dispatchEvent(new CustomEvent(UPDATE_REVIEW));
    await this.setState({ isLoading: false });
  }

  saveWriteReviewClickError() {
    console.log("saveWriteReviewClickError");
  }

  saveWriteReviewClickSuccess() {
    this.setState({ isLoading: true });
    const {
      showErrorNotification,
      showSuccessNotification
    } = this.props
    const {
      nickname,
      summary,
      comments,
      disadvantage,
      advantage,
      email,
      author_type,
      product_id,
      is_verified_buyer,
      selectedRatingProductValue
    } = this.state;

    const input = {
      nickname: nickname,
      email: email,
      summary: summary,
      content: comments,
      cons: advantage,
      pros: disadvantage,
      author_type: author_type,
      product_id: product_id,
      rating: selectedRatingProductValue,
      is_verified_buyer: is_verified_buyer
    };

    fetchMutation(
      AheadworkAdvanceReviewQuery.createAheadworksAdvancedReview(input)
    ).then(({ AheadworksAdvancedReview: response }) => {
      showSuccessNotification(__('Review is successfully added.'))
      this.setState({ isLoading: false, writeReview: false });
    },(error)=>{ showErrorNotification(error[0].message) });
  }

  changeNickname(value) {
    this.setState({ nickname: value });
  }
  changeSummary(value) {
    this.setState({ summary: value });
  }
  changeComments(value) {
    this.setState({ comments: value });
  }
  changeDisadvantage(value) {
    this.setState({ disadvantage: value });
  }
  changeAdvantage(value) {
    this.setState({ advantage: value });
  }
  changeEmail(value) {
    this.setState({ email: value });
  }

  updateProductIdIntoProduct(filters) {
    filters = {
      product_id: {
        eq: this.getProductId()
      }
    };

    return this.updateFilterOption(filters);
  }

  updateFilterOption(filters) {
    var { filterOptions } = this.state;

    var selectedFilter = {};

    filterOptions.forEach(function(value, key) {
      if (value.active) {
        selectedFilter = value;
      }
    });
    if (
      Object.keys(selectedFilter).length > 0 &&
      parseInt(selectedFilter.from_to) > 0
    ) {
      filters["rating"] = {
        eq: selectedFilter.from_to
      };
    }

    return filters;
  }

  writeReviewClick() {
    console.log("writeReviewClick");
  }

  getProductId() {
    const {
      product: { id }
    } = this.props;

    return id;
  }

  getRating(value) {
    return ((value * 5) / 100).toFixed(2);
  }

  setRecommendedProductValue(value) {
    this.setState({ selectedRecommendedProductValue: parseInt(value) });
  }

  render() {
    return (
      <AheadworkAdvanceReview
        {...this.props}
        {...this.containerFunctions}
        {...this.state}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AheadworkAdvanceReviewContainer);
