import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReviewComment from './ReviewComment.component';
import moment from 'moment';

/** @namespace Component/ReviewCommentContainer/Container */
export class ReviewCommentContainer extends PureComponent {

    static propTypes = {
        comment: PropTypes.object.isRequired
    };

    containerFunctions = {
        formatDate: this.formatDate.bind(this)
    }

    __construct(props) {
        super.__construct(props);

        this.state ={
            isLoading: false
        }
    }

    formatDate(date, format = 'YYYY-MM-DD') {
        var _date = new Date(date);
        return moment(_date).format(format)
    }

    render() {
        return (
            <ReviewComment 
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default ReviewCommentContainer;
