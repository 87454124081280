/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Component/OrderDeliveryDateComponent/Container */
export class OrderDeliveryDateComponent extends PureComponent {

    static propTypes = {
        order: PropTypes.object.isRequired
    };

    __construct(props) {
        super.__construct(props);
    }

    renderDeliveryTime() {

        const {
            order:{
                amasty_delivery:{
                    delivery_time
                }
            }
        }= this.props

        if(delivery_time == "") {
            return null
        }

        return (
            <>
                <dt>{ __('Time: ') }</dt>
                <dd>{ delivery_time }</dd>
            </>
        );
    }

    renderDeliveryComment() {

        const {
            order:{
                amasty_delivery:{
                    delivery_comment
                }
            }
        }= this.props

        if(delivery_comment == "") {
            return null
        }

        return (
            <>
                <dt>{ __('Comment: ') }</dt>
                <dd>{ delivery_comment }</dd>
            </>
        );
    }

    render() {

        const {
            order:{
                amasty_delivery:{
                    delivery_date
                }
            }
        }= this.props

        if(delivery_date == "") {
            return null
        }


        return (
            <div block="MyAccountOrderPopup" elem="DeliveryDate">
                <h4>{ __('Delivery Details') }</h4>
                <dl>
                    <dt>{ __('Date: ') }</dt>
                    <dd>{ delivery_date }</dd>
                    { this.renderDeliveryTime() }
                    { this.renderDeliveryComment() }
                </dl>
            </div>
        );
    }
}

export default OrderDeliveryDateComponent;
