import { isSignedIn } from 'Util/Auth';
import CheckoutQuery from 'Query/Checkout.query';
import { deleteGuestQuoteId, getGuestQuoteId } from 'Util/Cart';
import {
    fetchMutation,
} from 'Util/Request';
import { 
    PAYMENT_TAPPAY_LINE_PAY
} from '@elightwalk/tapPay/src/component/TapPay/TapPay.config';
import TapPayQuery from '@elightwalk/tapPay/src/query/TapPay.query'
import getStore from 'Util/Store';
import { setResponse } from '@elightwalk/tapPay/src/store/Tappay.action';
import {
    DETAILS_STEP,
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';


const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {

    const [{ paymentMethod: { code, additional_data } }] = args;

    if(code == PAYMENT_TAPPAY_LINE_PAY) {
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        if (!isCustomerSignedIn && !guest_cart_id) {
            return;
        }

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    tappay_line_pay: additional_data
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const { placeOrder: { order:{ order_id } } } = orderData;

            BrowserDatabase.setItem(true, PAYMENT_TAPPAY_LINE_PAY, 3600);

            const { startTapPayPayment:{ url } }= await fetchMutation(TapPayQuery.startTapPayPayment(order_id));
            window.location = url;

        } catch (e) {
            instance._handleError(e);
        }
    }else{
        return callback(...args)
    }
}

const __construct = async (args, callback, instance) => {

    const state = getStore().getState();
    const {
        ConfigReducer:{
            payment_tappay_active
        },
        TappayReducer:{response}
    } =state

    const LinePayRejected = BrowserDatabase.getItem(PAYMENT_TAPPAY_LINE_PAY);

    if(payment_tappay_active && Object.keys(response).length > 0 && response.status){

        const {
            order
        }=response

        const {
            resetCart,
            resetGuestCart,
            setResponse
        }= instance.props


        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        BrowserDatabase.setItem(false, PAYMENT_TAPPAY_LINE_PAY, 3600);

        await callback(...args)
        
        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }
        await instance.setState({checkoutStep: DETAILS_STEP, isGuestEmailSaved: true, email: order.email, orderID: order.increment_id})
        await setResponse({})
        
    }else if(LinePayRejected){
        
        const {
            resetCart,
            resetGuestCart,
            showErrorNotification
        } = instance.props

        BrowserDatabase.setItem(false, PAYMENT_TAPPAY_LINE_PAY, 3600);
        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);

        await callback(...args)
        
        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }
        showErrorNotification('Payment declined.')
        
        history.push(appendWithStoreCode('/'))

    }else{
        
        return callback(...args)
    }
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        setResponse: (params) => dispatch(setResponse(params)),
    };
 };

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder:{
                position: 100,
                implementation: savePaymentMethodAndPlaceOrder
            },
            __construct: {
                position: 100,
                implementation: __construct
            }
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};