import { PAYMENT_TAPPAY_LINE_PAY, PAYMENT_TAPPAY_DIRECT_PAY, PAYMENT_TAPPAY_GOOGLE_PAY } from '@elightwalk/tapPay/src/component/TapPay/TapPay.config';

const _getPaymentData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;
    switch (code) {
        case PAYMENT_TAPPAY_LINE_PAY:
            const PAYMENT_TAPPAY_LINE_PAY_PRIME = args[1][0]
            return {
                code,
                additional_data: {
                    prime: PAYMENT_TAPPAY_LINE_PAY_PRIME,
                    remember: false,
                    is_use_saved_card: false
                }
            };
        case PAYMENT_TAPPAY_DIRECT_PAY:
            const PAYMENT_TAPPAY_DIRECT_PAY_PRIME = args[1][0]
            return {
                code,
                additional_data: {
                    prime: PAYMENT_TAPPAY_DIRECT_PAY_PRIME,
                    remember: false,
                    is_use_saved_card: false
                }
            };
        case PAYMENT_TAPPAY_GOOGLE_PAY:
            const PAYMENT_TAPPAY_GOOGLE_PAY_PRIME = args[1][0]
            return {
                code,
                additional_data: {
                    prime: PAYMENT_TAPPAY_GOOGLE_PAY_PRIME,
                    remember: false,
                    is_use_saved_card: false
                }
            };
    }
    

    return callback(...args)
}

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData:{
                position: 1000,
                implementation: _getPaymentData
            }
        }
    }
};