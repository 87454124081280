import PropTypes from "prop-types";
import { PureComponent } from "react";
import ProductReviewRating from "Component/ProductReviewRating";
import { RECOMMANDED_PRODUCT_CODE, NOT_RECOMMANDED_PRODUCT_CODE } from "../AheadworkAdvanceReview/AheadworkAdvanceReview.config";
import ReviewComment from "../ReviewComment";
import Form from "Component/Form";
import Field from "Component/Field";
import { TEXTAREA_TYPE, TEXT_TYPE } from "Component/Field/Field.config";
import { isSignedIn } from 'Util/Auth';
import "./ReviewItemInfo.style";

/** @namespace Component/ReviewItemInfo/Component */
export class ReviewItemInfo extends PureComponent {
  static propTypes = {
    review: PropTypes.object.isRequired,
    addComment: PropTypes.bool.isRequired,
    nickname: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,

    // Functions
    formatDate: PropTypes.func.isRequired,
    addNewCommentFun: PropTypes.func.isRequired,
    updateNickname: PropTypes.func.isRequired,
    updateSummary: PropTypes.func.isRequired,
    saveCommentClickError: PropTypes.func.isRequired,
    saveCommentClickSuccess: PropTypes.func.isRequired,
    cancelBtn: PropTypes.func.isRequired
  };

  renderIsRecommend() {
    const {
      review: { product_recommended }
    } = this.props;

    if (product_recommended == RECOMMANDED_PRODUCT_CODE) {
      return (
        <div className="product_recommended recommend">
          {__("I recommend this product")}
        </div>
      );
    }
    return null
  }

  renderIsNotRecommend() {
    const {
      review: { product_recommended }
    } = this.props;

    if (product_recommended == NOT_RECOMMANDED_PRODUCT_CODE) {
      return (
        <div className="product_recommended recommend dont">
          {__("I don't recommend this product")}
        </div>
      );
    }

    return null
  }

  renderReviewListBar() {
    const {
      review: { nickname }
    } = this.props;

    return (
      <div className="review-list-sidebar-main">
        <div className="data-grid-cell-content nickname">{nickname}</div>
        <div className="data-grid-cell-content is_verified_buyer">
          {__("Verified Buyer")}
        </div>
        {this.renderIsRecommend()}
        {this.renderIsNotRecommend()}
      </div>
    );
  }

  renderReviewRightBar() {
    return (
      <div className="review-list-column-main">
        {this.renderReviewListTop()}
        {this.renderReviewListmiddle()}
      </div>
    );
  }

  renderReviewListTop() {
    const {
      review: { summary, rating, created_at },
      formatDate
    } = this.props;
    return (
      <div className="review-list-column-main-top">
        <div className="data-grid-cell-content summary">{summary}</div>
        <div className="data-grid-cell-content rating-list">
          <ProductReviewRating summary={rating || 0} />
        </div>
        <div className="data-grid-cell-content date">
          {formatDate(created_at, "MMM DD, YYYY")}
        </div>
      </div>
    );
  }

  renderReviewListmiddle() {
    const {
      review: { content, comments }
    } = this.props;
    return (
      <div className="review-list-content">
        <div className="review-list-column-main-middle">
          <div className="data-grid-cell-content review-data">{content}</div>
        </div>
        {comments != null &&
          comments.map(comment => (
            <ReviewComment key={comment.id} comment={comment} />
          ))}
        <div className="comments-block">
          {this.renderAddCommentForm()}
          {this.renderAddComment()}
        </div>
      </div>
    );
  }

  renderAddCommentForm() {
    const {
      // Variables
      addComment,
      nickname,
      summary,

      // Functions
      saveCommentClickError,
      saveCommentClickSuccess,
      updateNickname,
      updateSummary,
      cancelBtn
    } = this.props;

    if (addComment) {
      return null;
    }

    return (
      <Form
        id="AdvanceReviewComment"
        mix={{ block: "AdvanceReviewComment" }}
        onSubmitError={saveCommentClickError}
        onSubmitSuccess={saveCommentClickSuccess}
      >
        <div className="aw-ar-wrapper-fieldset">
          <div className="fieldset">
            <div className="field _required">
              <Field
                type={TEXT_TYPE}
                className="input-text"
                id="comment_nickname"
                name="comment_nickname"
                label={__("Nickname")}
                validation={["notEmpty"]}
                value={nickname}
                onChange={updateNickname}
              />
            </div>
            <div className="field _required">
              <Field
                type={TEXTAREA_TYPE}
                className="input-text"
                id="comment_text"
                name="comment_text"
                label={__("Comment Summary")}
                validation={["notEmpty"]}
                placeholder={__("Share your thoughts about the review")}
                value={summary}
                onChange={updateSummary}
              />
            </div>
          </div>
          <div className="actions-toolbar">
            <div className="primary">
              <button type="submit" className="Button">
                {__("Submit")}
              </button>
            </div>
            <div className="secondary">
              <button
                type="button"
                className="Button Button_isHollow"
                onClick={cancelBtn}
              >
                {__("Cancel")}
              </button>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  renderAddComment() {
    const { addNewCommentFun, addComment } = this.props;

    if(!addComment || !isSignedIn()) {
      return null
    }

    return (
      <div className="form-container">
        <div className="add-comment" onClick={ addNewCommentFun }>
          {__("Add Comment")}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="review-items">
        {this.renderReviewListBar()}
        {this.renderReviewRightBar()}
      </div>
    );
  }
}

export default ReviewItemInfo;
