import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchQuery, fetchMutation } from "Util/Request";
import { showNotification } from 'Store/Notification/Notification.action';
import PropTypes from "prop-types";

import AdvanceProductReview from './AdvanceProductReview.component';
import AheadworkAdvanceReviewQuery from "../../query/AheadworkAdvanceReview.query";
import { STATUS } from "../AheadworkAdvanceReview/AheadworkAdvanceReview.config";
import { SORTING_RATING, SORTING_CREATED, PAGE_SIZE, CURRENT_PAGE } from "./AdvanceProductReview.config";
import moment from 'moment';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Component/CheckoutBilling/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message))
});

/** @namespace Component/AdvanceProductReview/Container */
export class AdvanceProductReviewContainer extends PureComponent {

    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            isLoading: true,
            reviews: [],
            pageInfo: {},
            isNewCommentEmailEnabled: false,
            isReviewReminderEmailEnabled: false,
            isReviewApprovedEmailEnabled: false,
            ratingSorting: SORTING_RATING,
            createdSorting: SORTING_CREATED,
            pageSize: PAGE_SIZE,
            currentPage: CURRENT_PAGE
        }

        this.getEmailSubscriberConfig()
        this.getReviews()
    }

    containerFunctions = () => ({
        getStatus: this.getStatus,
        getReviews: this.getReviews,
        getCreatedValue: this.getCreatedValue,
        updateNotification: this.updateNotification.bind(this),
        changeIsNewCommentEmailEnabled: this.changeIsNewCommentEmailEnabled.bind(this),
        changeIsReviewReminderEmailEnabled: this.changeIsReviewReminderEmailEnabled.bind(this),
        changeIsReviewApprovedEmailEnabled: this.changeIsReviewApprovedEmailEnabled.bind(this),
        updateSortIcon: this.updateSortIcon.bind(this),
        onPageSelect: this.onPageSelect.bind(this),
        goToProductReviewPage: this.goToProductReviewPage
    });

    async getEmailSubscriberConfig() {
        const {
            showErrorNotification
        } = this.props

        await fetchQuery(AheadworkAdvanceReviewQuery.getEmailSubscriberConfig()).then(({getEmailSubscriberConfig: response}) => {
            this.setState({ 
                isNewCommentEmailEnabled: response.is_new_comment_email_enabled,
                isReviewReminderEmailEnabled: response.is_review_reminder_email_enabled,
                isReviewApprovedEmailEnabled: response.is_review_approved_email_enabled 
            })
        },(error)=>{ showErrorNotification(error[0].message) });
    }

    getSortingParams() {
        const {
            createdSorting
        } = this.state

        let _createdSorting= {}
        _createdSorting[createdSorting.code] =createdSorting.value

        let sort = { ..._createdSorting}

        return sort
    }

    getFiltersParams() {
        return {}
    }

    async getReviews(){

        const {
            showErrorNotification
        } = this.props

        const {
            pageSize,
            currentPage
        } = this.state

        await fetchQuery(
            AheadworkAdvanceReviewQuery.getCustomerAheadworksAdvancedReviewsQuery(
              this.getFiltersParams(),
              this.getSortingParams(),
              pageSize,
              currentPage
          )).then(({getCustomerAheadworksAdvancedReviews: response}) => {
            this.setState({ reviews: response.items, pageInfo: response.page_info, isLoading: false })
          },(error)=>{ showErrorNotification(error[0].message) });
    }

    updateNotification() {
        let {
            isNewCommentEmailEnabled,
            isReviewReminderEmailEnabled,
            isReviewApprovedEmailEnabled
        } = this.state
        const {
            showSuccessNotification
        } = this.props
        this.setState({isLoading: true})
        fetchMutation(
            AheadworkAdvanceReviewQuery.updateEmailSubscriberConfig(
                isReviewApprovedEmailEnabled,
                isNewCommentEmailEnabled,
                isReviewReminderEmailEnabled
          )).then(({updateEmailSubscriberConfig: response}) => {
            this.setState({ isLoading: false })
            showSuccessNotification(__('Notification updated successfully.'))
          },(error)=>{ showErrorNotification(error[0].message) });
    }

    getStatus = (statusCode) => {
        let status = ''
        STATUS.map(function(value){
            if(value.id == statusCode) {
                status = value.label
            }
        })
        return status
    }

    getCreatedValue(date, format='DD/MM/YYYY') {
        return moment(new Date(date)).format(format)
    }

    changeIsNewCommentEmailEnabled(value) {
        if(value == 'false') {
            this.setState({ isNewCommentEmailEnabled : true})
        }else{
            this.setState({ isNewCommentEmailEnabled : false})
        }
    }

    changeIsReviewReminderEmailEnabled(value) {
        if(value == 'false') {
            this.setState({ isReviewReminderEmailEnabled : true})
        }else{
            this.setState({ isReviewReminderEmailEnabled : false})
        }
    }

    changeIsReviewApprovedEmailEnabled(value) {
        if(value == 'false') {
            this.setState({ isReviewApprovedEmailEnabled : true})
        }else{
            this.setState({ isReviewApprovedEmailEnabled : false})
        }
    }

    updateSortIcon(event) {

        if(event.target.id) {
            const clickedColumn = event.target.id

            let {
                ratingSorting,
                createdSorting
            } = this.state

            // Check Order
            let defaultOrder = 'DESC'
            if(clickedColumn == ratingSorting.code) {
                if(ratingSorting.value == defaultOrder) {
                    ratingSorting.value = 'ASC'
                }else{
                    ratingSorting.value = 'DESC'
                }
                this.setState({ratingSorting: ratingSorting})
            }else if(clickedColumn == createdSorting.code){
                if(createdSorting.value == defaultOrder) {
                    createdSorting.value = 'ASC'
                }else{
                    createdSorting.value = 'DESC'
                }
                this.setState({createdSorting: createdSorting})
            }
        }

        this.getReviews()
    }

    async onPageSelect(event) {
        await this.setState({ currentPage : parseInt(event.target.textContent), isLoading: true })
        await this.getReviews()
    }

    goToProductReviewPage(product_url) {
        history.push({ pathname: appendWithStoreCode(product_url+'.html') });
    }

    render() {
        return (
            <AdvanceProductReview
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions() }
            />
        );
    }
}

export default connect(null, mapDispatchToProps)(AdvanceProductReviewContainer,);
