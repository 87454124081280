import { AMASTY_DELIVERY_CITY_UPDATE } from '@elightwalk/amasty-delivery-date/src/util/Event/Events'

const onAddressSelect = (args, callback, instance) => {
    const original = args[0]

    if (original.advance_city !== undefined) {
        const {
            advance_city: {
                city_id
            }
        } = original
        if (city_id > 0) {
            window.dispatchEvent(new CustomEvent(AMASTY_DELIVERY_CITY_UPDATE, { detail: city_id }));
        }
    }
    return callback(...args)

}

const componentDidUpdate = (args, callback, instance) => {
    if (args[1]) {
        const original = args[1]

        const {
            prevDefaultAddressId
        } = original

        const {
            selectedAddressId
        } = instance.state

        if (selectedAddressId !== prevDefaultAddressId) {

            const {
                customer: {
                    addresses
                }
            } = instance.props

            if (addresses.length > 0) {
                addresses.forEach(function (value) {

                    if (value.id == selectedAddressId) {
                        const {
                            advance_city: {
                                city_id
                            }
                        } = value
                        window.dispatchEvent(new CustomEvent(AMASTY_DELIVERY_CITY_UPDATE, { detail: city_id }));
                    }
                })
            }
        }
    }

    return callback(...args)
}

const componentDidMount = (args, callback, instance) => {

    const {
        selectedAddressId
    } = instance.state

    // Login Customer 
    if (selectedAddressId > 0) {

        let checkoutAddressTableBtn = document.querySelectorAll('.CheckoutAddressTable button');
        if (checkoutAddressTableBtn.length > 0) {
            checkoutAddressTableBtn.forEach(function (btn) {

                if (btn.className.indexOf('isSelected') > -1) {
                    setTimeout(function () {
                        btn.click()
                    }, 1000)
                }

            })
        }
    }
    return callback(...args)
}

export default {
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            onAddressSelect: {
                position: 100,
                implementation: onAddressSelect
            },
            componentDidUpdate: {
                position: 100,
                implementation: componentDidUpdate
            },
            componentDidMount: {
                position: 100,
                implementation: componentDidMount
            }
        }
    }
};