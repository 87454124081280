/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Field } from 'Util/Query';

export const _getStoreConfigFieldsAction = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('smilepay_atm').addFieldList([
            'smilepayatm_title',
            'smilepayatm_active'
        ])
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: {
                position: 100,
                implementation: _getStoreConfigFieldsAction
            }
        }
    }
};