import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SmilepayatmOrderSuccess from './SmilepayatmOrderSuccess.component';

/** @namespace Component/SmilepayatmOrderSuccess/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    config: state.ConfigReducer
});

/** @namespace Component/SmilepayatmOrderSuccess/Container */
export class SmilepayatmOrderSuccessContainer extends PureComponent {

    static propTypes = {
        details: PropTypes.object.isRequired,
        config: PropTypes.object
    };

    render() {

        const {
            config:{
                smilepay_atm:{
                    smilepayatm_active
                }
            }
        }= this.props

        if(!smilepayatm_active) {
            return null
        }
        
        return (
            <SmilepayatmOrderSuccess
                { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps)(SmilepayatmOrderSuccessContainer);
