/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import getStore from 'Util/Store';
export class CheckoutFieldFormPlugin {
    renderfieldMap = (args, callback, instance) => {

        var original = { ...args }
        const state = getStore().getState();

        // Check amasty_delivery_date enable or not
        if (state.ConfigReducer.amasty_delivery_date.general.enable) {

            const {
                isSubmitted
            } = callback.props

            const customFields = {
                postcode: {
                    isSubmitted,
                    label: __('Zip/Postal code'),
                    onBlur: (value) => callback.onZipcodeChange,
                    onChange: (value) => callback.onChange('postcode', value),
                    validateSeparately: false,
                    validation: ['notEmpty']
                },
                city_id: {
                    label: __('City'),
                    validation: ['notEmpty'],
                    type: 'select',
                    value: args.city_id.value,
                    onChange: (value) => callback.onChange("city_id", value),
                    selectOptions: args.city_id.selectOptions
                },
                surb_id: {
                    label: args.surb_id.label,
                    type: args.surb_id.type,
                    value: args.city_id.value,
                    onChange: (value) => callback.onChange("surb_id", value),
                    selectOptions: args.surb_id.selectOptions,
                    mix: args.surb_id.mix
                },
                street: {
                    isSubmitted,
                    label: __('Street address'),
                    validateSeparately: false,
                    validation: ['notEmpty'],
                    value: args.street.value,
                },
                telephone: {
                    isSubmitted,
                    label: __('Phone number'),
                    validateSeparately: false,
                    validation: ['notEmpty', 'telephone']
                }
            }
            delete args.postcode
            delete args.telephone
            delete args.street
            delete args.city_id
            delete args.surb_id

            original = { ...args, ...customFields }
            
        }
        return original
    };
}

export const { renderfieldMap } = new CheckoutFieldFormPlugin();

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            fieldMap: {
                position: 300,
                implementation: renderfieldMap
            }
        }
    },
};