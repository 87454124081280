import SmilepayatmOrderSuccess from '../component/SmilepayatmOrderSuccess'
import { SMILEPAY_ATM_CODE } from '@elightwalk/smilepay/src/component/SmilepayatmOrderSuccess/SmilepayatmOrderSuccess.config';


export class CheckoutSuccessComponentPlugin {
    renderActions = (args, callback, instance) => {

        if(instance.props.order) {
            return (
                <>
                    { this.renderSmilepayOrderSuccess(instance) }
                    { callback.apply(instance, args) }
                </>
            );
        }
        
        return (
            <>
                { callback.apply(instance, args) }
            </>
        );
        
    };

    renderSmilepayOrderSuccess(instance) {

        const{
            order:{
                smilepayatm_payment_info: {
                    method,
                    payment_smilepayatm
                }
            }
        }= instance.props

        if(method == SMILEPAY_ATM_CODE){
            return (
                <SmilepayatmOrderSuccess 
                    details= { payment_smilepayatm }
                />
            );
        }

        return null
    }

}

export const { renderActions } = new CheckoutSuccessComponentPlugin();

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render: {
                position: 100,
                implementation: renderActions
            }
        }
    }
};