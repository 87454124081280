import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { appendWithStoreCode } from 'Util/Url';
export const FaqPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */
    './../route/FaqPage'
));

export const FaqSearch = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */
    './../route/FaqSearch'
));

export const FaqSearchResult = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */
    './../route/FaqSearchResult'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: <Route path={appendWithStoreCode('/faqs/search')} render={(props) => <FaqSearch {...props} />} />,
        position: 100
    },
    {
        component: <Route path={appendWithStoreCode('/faqs/:category_name/:articles_list')} render={(props) => <FaqSearchResult {...props} />} />,
        position: 100
    },
    {
        component: <Route path={appendWithStoreCode('/faqs')} render={(props) => <FaqPage {...props} />} />,
        position: 100
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};