/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import "./GooglePay.container.style";
import BrowserDatabase from 'Util/BrowserDatabase';
import { 
    GOOGLE_PAY_OPEN_BTN,
    GOOGLE_PAY_PRIME,
    ONE_HOUR
} from '@elightwalk/tapPay/src/component/TapPay/TapPay.config';

/** @namespace Component/GooglePay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    configData: state.ConfigReducer,
    cartData: state.CartReducer
});

/** @namespace Component/GooglePay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Component/GooglePay/Container */
export class GooglePayContainer extends PureComponent {

    static prime() {
        return new Promise((resolve, reject) => {
            resolve(BrowserDatabase.getItem(GOOGLE_PAY_PRIME))
        });
    }

    setupTPDirect(){
        const {
            configData:{
                payment_tappay_server_type,
                payment_tappay_app_id,
                payment_tappay_app_key
            }
        } = this.props
        TPDirect.setupSDK(parseInt(payment_tappay_app_id), payment_tappay_app_key, payment_tappay_server_type)

        return null
    }

    initGooglePay() {
        let that = this
        const {
            configData:{
                payment_tappay_tappay_google_pay_merchant_id,
                payment_tappay_tappay_google_pay_merchant_name,
                payment_tappay_tappay_google_pay_require_email,
                payment_tappay_tappay_google_pay_require_shipping_address,
                payment_tappay_tappay_google_pay_require_billing_address,
                payment_tappay_tappay_google_pay_billing_address_format,
                payment_tappay_tappay_google_pay_allow_prepaid_cards,
                payment_tappay_tappay_google_pay_allowed_country,
                payment_tappay_tappay_google_pay_require_phone_number
            },
            cartData:{
                cartTotals:{
                    subtotal,
                    quote_currency_code
                }
            }
        } = this.props

        const googlePaySetting = {
            googleMerchantId: payment_tappay_tappay_google_pay_merchant_id,
            allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            merchantName: payment_tappay_tappay_google_pay_merchant_name,
            emailRequired: payment_tappay_tappay_google_pay_require_email,
            shippingAddressRequired: payment_tappay_tappay_google_pay_require_shipping_address,
            billingAddressRequired: payment_tappay_tappay_google_pay_require_billing_address,
            billingAddressFormat: payment_tappay_tappay_google_pay_billing_address_format,

            allowPrepaidCards: payment_tappay_tappay_google_pay_allow_prepaid_cards,
            allowedCountryCodes: payment_tappay_tappay_google_pay_allowed_country.split(","),

            phoneNumberRequired: payment_tappay_tappay_google_pay_require_phone_number
        }
        TPDirect.googlePay.setupGooglePay(googlePaySetting);

        var paymentRequest = {
            allowedNetworks: ["AMEX", "JCB", "MASTERCARD", "VISA"],
            price: parseFloat(subtotal).toString(),
            currency: quote_currency_code
        }

        TPDirect.googlePay.setupPaymentRequest(paymentRequest, function (err, result) {
            if (result.canUseGooglePay) {
                that.setGooglePayBtn()
            }
        })

        return null
    }

    setGooglePayBtn() {
        BrowserDatabase.setItem(true, GOOGLE_PAY_OPEN_BTN, ONE_HOUR)
        const {showNotification, setOrderButtonVisibility}=this.props
        TPDirect.googlePay.setupGooglePayButton({
            el: "#tappay-google-pay-start-button",
            color: "black",
            type: "long",
            getPrimeCallback: async function (err, prime) {
                if (prime) {
                    await document.getElementById('tappay-google-pay-start-button').remove()
                    BrowserDatabase.setItem(prime, GOOGLE_PAY_PRIME, ONE_HOUR)
                    showNotification('success', __('Payment is successfully done.'))
                    setOrderButtonVisibility(true)
                    document.querySelector('.CheckoutBilling-Button').click()
                } else {
                    showNotification('error', err.msg)
                }
            }
        })

        return null
    }

    render() {
        let that = this
        const { showNotification, setOrderButtonVisibility, setLoading, configData:{payment_tappay_tappay_google_pay_active}} = this.props
        
        const openBtn = BrowserDatabase.getItem(GOOGLE_PAY_OPEN_BTN)
        setOrderButtonVisibility(false)
        if(payment_tappay_tappay_google_pay_active){
            if(!openBtn){
                setLoading(true)
                setTimeout(async function(){
                    BrowserDatabase.deleteItem(GOOGLE_PAY_PRIME)
                    await that.setupTPDirect()
                    await that.initGooglePay()
                    setLoading(false)
                }, 2000)
            }
            
        }else{
            showNotification('error', __('Google Pay is not enable.'))
        }

        return (
            <div className="control">
                <div id="tappay-google-pay-start-button" className="control" />
            </div>
        );

        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GooglePayContainer);
