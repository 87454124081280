/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

export class CheckoutQueryPlugin {

    _getOrderFieldAction = (args, callback, instance) => {
        return [
            ...callback(...args),
            this._getOrderProductField()
        ]
    };

    _getOrderProductField() {
        return new Field('elightwalk_order_products')
            .addFieldList([
                'id',
                'name'
            ]);
    }
}

export const { _getOrderFieldAction } = new CheckoutQueryPlugin();

export default {
    'Query/Checkout': {
        'member-function': {
            _getOrderFieldDetails: {
                position: 100,
                implementation: _getOrderFieldAction
            }
        }
    }
};