/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Field } from 'Util/Query';

/** @namespace Elightwalk/TapPay/Query */
export class TapPayQuery {
    startTapPayPayment(orderId) {
        return new Field('startTapPayPayment')
            .addArgument('increment_id', 'String!', orderId)
            .addFieldList(['url']);
    }

    responseTapPayPayment(params) {
        return new Field('responseTapPayPayment')
            .addArgument('input', 'ResponseTapPayPaymentInput', params)
            .addFieldList(this._responseTapPayPayment());
    }

    _responseTapPayPayment() {
        return [
            'message',
            'status',
            this.orderDetailFields()
        ]
    }

    orderDetailFields() {
        return new Field('order')
            .addFieldList(['email', 'increment_id']);
    }
}

export default new (TapPayQuery)();
