/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_STORELIST } from './StoreSwitcher.action';

/** @namespace Store/StoreSwitcher/Reducer/getInitialState */
export const getInitialState = () => ({
    availableStores: []
});

/** @namespace Store/StoreSwitcher/Reducer */
export const StoreSwitcherReducer = (
    state = getInitialState(),
    action
) => {

    switch (action.type) {
        case UPDATE_STORELIST:
            const {availableStores} = action;    

            return {
                ...state,
                availableStores
            };

        default:
            return state;
    }

};

export default StoreSwitcherReducer;