import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { appendWithStoreCode } from 'Util/Url';
export const ShippingTrackPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */
    './../route/ShippingTrackPage'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: <Route path={appendWithStoreCode('/shipping/tracking/popup')} render={(props) => <ShippingTrackPage {...props} />} />,
        position: 100
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
