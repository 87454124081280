/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from "prop-types";
import { PureComponent } from "react";
import "./CheckoutShippingDeliveryDate.style";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import Field from "Component/Field";
import { SELECT_TYPE, TEXTAREA_TYPE } from "Component/Field/Field.config";

import moment from "moment";

import enUS from "date-fns/locale/en-US";
import zhTW from "date-fns/locale/zh-TW";

/** @namespace Component/CheckoutShippingDeliveryDate/Component */
export class CheckoutShippingDeliveryDate extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
    selectedTimeValue: PropTypes.number.isRequired,
    setTimeValue: PropTypes.func.isRequired,
    getTimeOptions: PropTypes.func.isRequired,
    commentValue: PropTypes.string,
    dateValue: PropTypes.string.isRequired,
    changeDateValue: PropTypes.func.isRequired,
    updateCommentValue: PropTypes.func.isRequired,
    setDeliveryDateIntoCart: PropTypes.func.isRequired,
    dateValueError: PropTypes.string,
    commentValueError: PropTypes.string,
    selectedTimeValueError: PropTypes.string,
    disabledDates: PropTypes.array.isRequired,
    minimumDay: PropTypes.string.isRequired,
    maximumDay: PropTypes.string.isRequired,
  };

  __construct(props) {
    super.__construct(props);
    this.state = {
      locale: this.props.config.code,
      date: null,
    };
  }

  renderTime() {
    const {
      config: {
        amasty_delivery_date: {
          time: { enabled_time, note },
          general: { tintervals },
        },
      },
      getTimeOptions,
      selectedTimeValue,
      setTimeValue,
      selectedTimeValueError,
    } = this.props;

    if (!enabled_time) {
      return null;
    }

    const dropdownOptions = getTimeOptions(tintervals);

    return (
      <>
        <Field
          id="amdeliverydate_time"
          name="amdeliverydate_time"
          type={SELECT_TYPE}
          label={__("Delivery Time Interval")}
          validation={["notEmpty"]}
          mix={{ block: "AmDeliveryDateTime", elem: "Select" }}
          selectOptions={dropdownOptions}
          value={selectedTimeValue}
          onChange={setTimeValue}
        />
        {note != null &&
          <span block="Fieldnote" elem="Message">{note}</span>
        }
        {selectedTimeValueError != null && (
          <span block="Field" elem="Message">
            {selectedTimeValueError}
          </span>
        )}
      </>
    );
  }

  renderComments() {
    const {
      config: {
        amasty_delivery_date: {
          comment: { enabled_comment, note },
        },
      },
      commentValue,
      updateCommentValue,
      commentValueError,
    } = this.props;

    if (!enabled_comment) {
      return null;
    }

    return (
      <>
        <Field
          type={TEXTAREA_TYPE}
          label={__("Delivery Comments")}
          id="amdeliverydate_comment"
          name="amdeliverydate_comment"
          autocomplete="amdeliverydate_comment"
          validation={["notEmpty"]}
          value={commentValue}
          onChange={updateCommentValue}
        />
        {note != null &&
          <span block="Fieldnote" elem="Message">{note}</span>
        }
        {commentValueError != null && (
          <span block="Field" elem="Message">
            {commentValueError}
          </span>
        )}
      </>
    );
  }

  render() {
    const {
      changeDateValue,
      config: {
        amasty_delivery_date: {
          general: { enable },
          date: { note },
        },
        code,
      },
      dateValue,
      dateValueError,
      disabledDates,
      minimumDay,
      maximumDay,
    } = this.props;

    if (!enable) {
      return null;
    }

    const _disabledDates = this.makeFormatDateForReactDateRange(disabledDates);

    var localeCode = zhTW
    if (code == 'en') {
      localeCode = enUS
    }
    return (
      <div block="CheckoutAdditional" elem="ShippingContent">
        <div
          block="CheckoutAdditional"
          elem="fieldset"
          mix={{ block: "FieldForm" }}
        >
          <div block="DateFieldCol">
            <label htmlFor="date">{__("Delivery Date")}</label>

            <Calendar
              weekStartsOn={0}
              locale={localeCode}
              minDate={this.makeFormatDateForReactDateRange(minimumDay)}
              maxDate={this.makeFormatDateForReactDateRange(maximumDay)}
              date={this.makeFormatDateForReactDateRange(dateValue)}
              onChange={changeDateValue}
              disabledDates={_disabledDates}
            />
            {note != null && (
              <span block="Fieldnote" elem="Message">
                {note}
              </span>
            )}
            {dateValueError != null && (
              <span block="Field" elem="Message">
                {dateValueError}
              </span>
            )}
          </div>
          {this.renderTime()}
          {this.renderComments()}
        </div>
      </div>
    );
  }

  makeFormatDateForReactDateRange(data) {
    if (Array.isArray(data)) {
      var dateColl = [];
      data.forEach(function (value) {
        dateColl.push(new Date(moment(value.toString())));
      });
      return dateColl;
    } else {
      return new Date(moment(data));
    }
  }
}

export default CheckoutShippingDeliveryDate;
