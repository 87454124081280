import { TapPayContainer } from '@elightwalk/tapPay/src/component/TapPay/TapPay.container';
import { DirectPayContainer } from '@elightwalk/tapPay/src/component/DirectPay/DirectPay.container';
import { GooglePayContainer } from '@elightwalk/tapPay/src/component/GooglePay/GooglePay.container';
import { 
    TAPPAY_SCRIPT_URL, 
    PAYMENT_TAPPAY_LINE_PAY, 
    PAYMENT_TAPPAY_DIRECT_PAY, 
    PAYMENT_TAPPAY_GOOGLE_PAY, 
    GOOGLE_PAY_SCRIPT_URL,
    GOOGLE_PAY_OPEN_BTN,
    ONE_HOUR
} from '@elightwalk/tapPay/src/component/TapPay/TapPay.config';
import getStore from 'Util/Store';
import DirectPay from '@elightwalk/tapPay/src/component/DirectPay';
import GooglePay from '@elightwalk/tapPay/src/component/GooglePay';
import BrowserDatabase from 'Util/BrowserDatabase';

export class CheckoutPaymentsContainerPlugin { 

    dataMap = (original) =>{
        return {
            ...original,
            [PAYMENT_TAPPAY_LINE_PAY]: this.getTapPayData.bind(this),
            [PAYMENT_TAPPAY_DIRECT_PAY]: this.getTapPayDirectData.bind(this),
            [PAYMENT_TAPPAY_GOOGLE_PAY]: this.getTapPayGoogleData.bind(this)
        }
    }

    getTapPayData(){
        return { asyncData: TapPayContainer.prime() };
    }

    getTapPayDirectData() {
        return { asyncData: DirectPayContainer.prime() };
    }

    getTapPayGoogleData() {
        return { asyncData: GooglePayContainer.prime() };
    }

    selectPaymentMethod = async (args, callback, instance) => {

        const state = getStore().getState();

        const {
            selectedPaymentCode
        } = instance.state
        
        if(state.ConfigReducer.payment_tappay_active && selectedPaymentCode == PAYMENT_TAPPAY_LINE_PAY) {
            
            await this.getTapPayData()
            if (window.formPortalCollector) {
                window.formPortalCollector.subscribe(BILLING_STEP, this.collectAdditionalData, 'CheckoutPaymentsContainer');
            }
        }

        if(state.ConfigReducer.payment_tappay_active && selectedPaymentCode == PAYMENT_TAPPAY_DIRECT_PAY) {
            
            await this.getTapPayDirectData()
            if (window.formPortalCollector) {
                window.formPortalCollector.subscribe(BILLING_STEP, this.collectAdditionalData, 'CheckoutPaymentsContainer');
            }
        }

        if(state.ConfigReducer.payment_tappay_active && selectedPaymentCode == PAYMENT_TAPPAY_GOOGLE_PAY) {
            await this.getTapPayGoogleData()
            if (window.formPortalCollector) {
                window.formPortalCollector.subscribe(BILLING_STEP, this.collectAdditionalData, 'CheckoutPaymentsContainer');
            }
        }

        return callback(...args)
    }

    __construct = (args, callback, instance) => {

        const state = getStore().getState();

        // add tappaysdk
        if(state.ConfigReducer.payment_tappay_active) {
            var script = document. createElement("script");
            script.type = "text/javascript";
            script.src = TAPPAY_SCRIPT_URL;
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        // add googlepay
        if(state.ConfigReducer.payment_tappay_tappay_google_pay_active) {
            var script = document. createElement("script");
            script.type = "text/javascript";
            script.src = GOOGLE_PAY_SCRIPT_URL;
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        return callback(...args)
    }

    collectAdditionalData = (args, callback, instance) => {
        const { selectedPaymentCode } = instance.state;
        if(selectedPaymentCode == PAYMENT_TAPPAY_LINE_PAY || selectedPaymentCode == PAYMENT_TAPPAY_DIRECT_PAY || selectedPaymentCode == PAYMENT_TAPPAY_GOOGLE_PAY){
            const additionalDataGetter = instance.dataMap[selectedPaymentCode];
            if (!additionalDataGetter) {
                return {};
            }

            return additionalDataGetter();
        }

        return callback(...args)
    }
}



export class CheckoutPaymentsComponentPlugin { 

    paymentRenderMap = (original, instance) => {
        return {
            ...original,
            [PAYMENT_TAPPAY_DIRECT_PAY]: this.getDirectPayComponent.bind(instance),
            [PAYMENT_TAPPAY_GOOGLE_PAY]: this.getGooglePayComponent.bind(instance)
        }
    }

    getDirectPayComponent() {
        const { setOrderButtonEnableStatus, setOrderButtonVisibility, setLoading } = this.props;
        return <DirectPay 
            setOrderButtonEnableStatus={setOrderButtonEnableStatus} 
            setOrderButtonVisibility={setOrderButtonVisibility}
            setLoading={setLoading}
        />;
    }

    getGooglePayComponent() {
        const { setOrderButtonVisibility, setLoading } = this.props;
        BrowserDatabase.setItem(false, GOOGLE_PAY_OPEN_BTN, ONE_HOUR)
        return <GooglePay 
            setOrderButtonVisibility={setOrderButtonVisibility} 
            setLoading={setLoading}
        />;
    }
}

export const { dataMap, selectPaymentMethod, __construct, collectAdditionalData } = new CheckoutPaymentsContainerPlugin();

export const { paymentRenderMap } = new CheckoutPaymentsComponentPlugin();

export default {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap:{
                position: 1000,
                implementation: dataMap
            }
        },
        'member-function': {
            selectPaymentMethod:{
                position: 1000,
                implementation: selectPaymentMethod
            },
            __construct:{
                position: 100,
                implementation: __construct
            },
            collectAdditionalData:{
                position: 100,
                implementation: collectAdditionalData
            }
        }
    },
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap:{
                position: 1000,
                implementation: paymentRenderMap
            }
        },
    }
};