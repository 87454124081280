/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import '../component/AddressForm.style';

import getStore from 'Util/Store';
export class AddressFormPlugin {

    renderfieldMap = (args, callback, instance) => {

        var original = { ...args }

        const originallastname = { ...args.lastname }
        const originalfirstname = { ...args.firstname }
        const originaltelephone = { ...args.telephone}
        const originalCityId = { ...args.city_id }
        const originalSurbid = { ...args.surb_id }
        const originalstreet = { ...args.street }
        const originalpostcode = { ...args.postcode }
     
        const state = getStore().getState();

        // Check amasty_delivery_date enable or not
        if (state.ConfigReducer.amasty_delivery_date.general.enable) {

            const customFields = {
                country_id: {
                    label: args.country_id.label,
                    onChange: args.country_id.onChange,
                    selectOptions: args.country_id.selectOptions,
                    type: args.country_id.type,
                    validation: args.country_id.validation,
                    value: args.country_id.value,
                    mix: { block: 'AmDeliveryDateCountryIdDisable' }
                },
                city: {
                    label: args.city.label,
                    onChange: args.city.onChange,
                    value: "-",
                    mix: { block: 'AmDeliveryDateCityDisable' }
                },
                region_string: {
                    label: args.region_string.label,
                    validation: args.region_string.validation,
                    value: "-",
                    mix: { block: 'AmDeliveryDateregion_stringDisable' }
                },
                lastname: {
                    label: originallastname.label,
                    validation: originallastname.validation,
                    validateSeparately: originallastname.validateSeparately,
                    isSubmitted: originallastname.isSubmitted
                },
                firstname: {
                    label: originalfirstname.label,
                    validation: originalfirstname.validation,
                    validateSeparately: originalfirstname.validateSeparately,
                    isSubmitted: originalfirstname.isSubmitted
                }, 
                postcode: {
                    isSubmitted: originalpostcode.isSubmitted,
                    validation: originalpostcode.validation, 
                    label: originalpostcode.label,
                    onBlur: (value) => originalpostcode.onBlur,
                    // onChange: (value) => callback.onChange('postcode', value),
                    validateSeparately: originalpostcode.validateSeparately
                },
                city_id: {
                    label: originalCityId.label,
                    validation: ['notEmpty'],
                    type: originalCityId.type,
                    value: originalCityId.value,
                    onChange: (value) => originalCityId.onChange(value),
                    selectOptions: originalCityId.selectOptions
                },
                surb_id: {
                    label: originalSurbid.label,
                    type: originalSurbid.type,
                    value: originalSurbid.value,
                    onChange: (value) => originalSurbid.onChange(value),
                    selectOptions: originalSurbid.selectOptions,
                    mix: originalSurbid.mix
                },
                street: {
                    isSubmitted: originalstreet.isSubmitted,
                    label: originalstreet.label,
                    validateSeparately: originalstreet.validateSeparately,
                    validation: ['notEmpty'],
                    value: originalstreet.value
                },
                telephone: {
                    isSubmitted: originaltelephone.isSubmitted,
                    label: originaltelephone.label,
                    validateSeparately: originaltelephone.validateSeparately,
                    validation: ['notEmpty', 'telephone']
                }

            }

            delete args.firstname
            delete args.lastname
            delete args.telephone
            delete args.city_id
            delete args.surb_id 
            delete args.street
            delete args.postcode

            original = { ...args, ...customFields }

        }

        return original

    };

}
export const { renderfieldMap } = new AddressFormPlugin();

export default {
    'Component/MyAccountAddressForm/Component': {
        'member-function': {
            fieldMap: {
                position: 400,
                implementation: renderfieldMap
            }
        }
    }
};