/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const addAheadworkAdvanceReviewFields = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('aheadworks_advanced_review').addFieldList(['aggregated_rating', 'reviews_count'])
    ];
};

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: addAheadworkAdvanceReviewFields
        }
    }
};