/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './DeliveryDateSummary.style';

/** @namespace Component/DeliveryDateSummary/Component */
export class DeliveryDateSummary extends PureComponent {

    static propTypes = {
        amastyDelivery: PropTypes.object.isRequired
    };

    renderDeliveryDate()
    {
        const {
            amastyDelivery:{
                delivery_date
            }
        } = this.props

        return (
            <div block="shipping-delivery-row">
                <span block="label">{ __('Delivery Date :') }</span>
                <span block="value">{ delivery_date }</span>
            </div>
        );
    }

    renderTime()
    {
        const {
            amastyDelivery:{
                delivery_time
            }
        } = this.props

        if(delivery_time == "" || delivery_time == null) {
            return null;
        }

        return (
            <div block="shipping-delivery-row">
                <span block="label">{ __('Delivery Time:') }</span>
                <span block="value">{ delivery_time }</span><br />
            </div>
        );
    }

    renderComment()
    {
        const {
            amastyDelivery:{
                delivery_comment
            }
        } = this.props

        if(delivery_comment == "" || delivery_comment == null) {
            return null;
        }

        return (
            <div block="shipping-delivery-row">
                <span block="label">{ __('Delivery Comment:') }</span>
                <span block="value">{ delivery_comment }</span>
            </div>
        );
    }

    render() {

        const {
            amastyDelivery:{
                delivery_date
            }
        } = this.props

        if(delivery_date == "" || delivery_date == null) {
            return null;
        }

        return (
            <div block="DeliveryDateSummary" elem="Block">
                <div block="DeliveryDateSummary" elem="Title">
                    { __('Delivery Date ') }
                </div>
                <div block="DeliveryDateSummary" elem="Content">
                    { this.renderDeliveryDate() }
                    { this.renderTime() }
                    { this.renderComment() }
                </div>
            </div>
        );
    }
}

export default DeliveryDateSummary;
