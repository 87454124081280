import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchQuery } from 'Util/Request';
import SocialNetworksQuery from '../../query/SocialNetworks.query';
import SocialAuthentication from './SocialAuthentication.component';

/** @namespace ScandiPWA/SocialLoginGraphql/Component/SocialAuthentication/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace ScandiPWA/SocialLoginGraphql/Component/SocialAuthentication/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace ScandiPWA/SocialLoginGraphql/Component/SocialAuthentication/Container */
export class SocialAuthenticationContainer extends PureComponent {
    static propTypes = {
        buttons: PropTypes.arrayOf(PropTypes.shape),
        isMyAccount: PropTypes.bool
    };

    static defaultProps = {
        buttons: null,
        isMyAccount: false
    };

    componentDidMount() {
        this.requestButtons();
    }

    componentDidUpdate(prevProps) {
        const { buttons: prevButtons } = prevProps;
        const { buttons } = this.props;

        if (prevButtons !== buttons) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ buttonsList: buttons });
        }
    }

    __construct(props) {
        super.__construct(props);

        const { buttons } = this.props;

        this.state = {
            buttonsList: buttons
        };
    }

    /**
     * Request enabled social buttons in they was not provided in props
     *
     * @returns {*}
     */
    requestButtons() {
        const { buttonsList } = this.state;

        if (!buttonsList || !buttonsList.length) {
            const query = SocialNetworksQuery.getSocialButtonsQuery();

            return fetchQuery(query).then(
                /** @namespace ScandiPWA/SocialLoginGraphql/Component/SocialAuthentication/Container/fetchQueryThen */
                ({ socialLogin_buttons: socialButtons }) => {
                    this.setState({ buttonsList: socialButtons });
                }
            );
        }

        return null;
    }

    render() {
        const { isMyAccount } = this.props;
        const { buttonsList } = this.state;

        return (
            <SocialAuthentication
              isMyAccount={ isMyAccount }
              buttonsList={ buttonsList }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    SocialAuthenticationContainer,
);
