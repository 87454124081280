/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

export class CartQueryPlugin {

    _getCartTotalsFieldsAction = (args, callback, instance) => {
        return [
            ...callback(...args),
            new Field('amasty_delivery').addFieldList([
                'delivery_comment',
                'delivery_time_id',
                'delivery_date',
                'delivery_time'
            ])
        ];
    };
}

export const { _getCartTotalsFieldsAction } = new CartQueryPlugin();

export default {
    'Query/Cart': {
        'member-function': {
            _getCartTotalsFields: {
                position: 10,
                implementation: _getCartTotalsFieldsAction
            }
        }
    }
};