import PropTypes from 'prop-types';
import AdvanceProductReview from '../component/AdvanceProductReview';

export const PRODUCT_REVIEWS = 'product-reviews';

export class MyAccountComponentPlugin {
    renderMap = originalMember => ({
        ...originalMember,
        [PRODUCT_REVIEWS]: AdvanceProductReview
    });

    propTypes = originalMember => ({
        ...originalMember,
        activeTab: PropTypes.oneOfType([
            originalMember.activeTab,
            PropTypes.oneOf([PRODUCT_REVIEWS])
        ])
    });
}

// eslint-disable-next-line
export class MyAccountContainerPlugin {
    tabMap = originalMember => ({
        ...originalMember,
        [PRODUCT_REVIEWS]: {
            url: '/product-reviews',
            name: __('My Product Reviews')
        }
    });
}

/* eslint-disable-next-line */
export const { renderMap, propTypes } = new MyAccountComponentPlugin();
export const { tabMap } = new MyAccountContainerPlugin();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: [
                {
                    position: 101,
                    implementation: renderMap
                }
            ]
        },
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: propTypes
                }
            ]
        }
    },
    'Component/MyAccountTabList/Component': {
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: propTypes
                }
            ]
        }
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: [
                {
                    position: 101,
                    implementation: tabMap
                }
            ]
        }
    }
};
