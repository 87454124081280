/* eslint-disable import/prefer-default-export */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export const PAGE_SIZE = 5
export const CURRENT_PAGE = 1

export const SORTING_RATING = {
    code: 'rating',
    value: 'DESC',
}

export const SORTING_CREATED = {
    code: 'created_at',
    value: 'DESC',
}
