export const DeliveryDateDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/Amasty/DeliveryDate.dispatcher'
);
import getStore from 'Util/Store';

const __construct = (args, callback, instance) => {

    const state = getStore().getState();

    // Check amasty_delivery_date enable or not
    if (state.ConfigReducer.amasty_delivery_date.general.enable) {

        const {
            getMagentovnAdvancedCities
        } = instance.props

        // Condition because of product page
        if (getMagentovnAdvancedCities) {
            getMagentovnAdvancedCities()
        }
    }

    return callback(...args)
}

const mapDispatchToProps = (args, callback, instance) => {

    const [dispatch] = args;
    const original = callback(...args);
    const state = getStore().getState();

    // Check amasty_delivery_date enable or not
    if (!state.ConfigReducer.amasty_delivery_date.general.enable) {
        return {
            ...original
        }
    }

    return {
        ...original,
        getMagentovnAdvancedCities: () => DeliveryDateDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getMagentovnAdvancedCities(dispatch)
        )
    };
};

export default {
    'Component/MyAccountAddressBook/Container': {
        'member-function': {
            __construct: {
                position: 100,
                implementation: __construct
            }
        }
    },
    'Component/MyAccountAddressBook/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};