/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import DeliveryDateSummary from '../component/DeliveryDateSummary'
import ExpandableContent from 'Component/ExpandableContent';

export class CheckoutOrderSummaryComponentPlugin {

    renderContentActions = (args, callback, instance) => {
        const { isExpandable } = instance.props;

        if (isExpandable) {
            return instance.renderExpandableContent();
        }

        return (
            <>
                { instance.renderHeading() }
                { instance.renderItems() }
                { instance.renderTotals() }
                { this.renderDeliveryDateSummary() }
            </>
        );
    };

    renderExpandableContentActions = (args, callback, instance) => {
        return (
            <ExpandableContent
              heading={ __('Order summary') }
              mix={ { block: 'CheckoutOrderSummary', elem: 'ExpandableContent' } }
            >
                { instance.renderItems() }
                { this.renderDeliveryDateSummary() }
                { instance.renderCmsBlock() }
                { instance.renderCoupon() }
                { instance.renderTotals() }
            </ExpandableContent>
        );
    };

    renderDeliveryDateSummary()
    {
        return (
            <DeliveryDateSummary />
        );
    }

}

export const { renderContentActions, renderExpandableContentActions, renderCheckoutShippingDeliveryDateAction } = new CheckoutOrderSummaryComponentPlugin();

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderContent: {
                position: 200,
                implementation: renderContentActions
            },
            renderExpandableContent:{
                position: 200,
                implementation: renderExpandableContentActions
            }
        }
    }
};