/* eslint-disable import/prefer-default-export */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const PRODUCT_REVIEW_PAGE = '/my-account/product-reviews';
export const PAGE_SIZE = 5;
export const CURRENT_PAGE = 1;
export const TOTAL_PAGE = 1;
export const FILTERS = {
    product_id: {
        eq: ''
    }
};
export const RECOMMANDED_PRODUCT_CODE = 2;
export const NOT_RECOMMANDED_PRODUCT_CODE = 1;

export const RECOMMEND_PRODUCT_OPTIONS =[
    {
        id: 0,
        name: __('Not specified'),
        value: 0,
        label: __('Not specified')
    },
    {
        id: 1,
        name: __('No'),
        value: 1,
        label: __('No')
    },
    {
        id: 2,
        name: __('Yes'),
        value: 2,
        label: __('Yes')
    }
]

export const AUTHOR_TYPE_GUEST = 1
export const AUTHOR_TYPE_CUSTOMER = 2

export const IS_VERIFIED_BUYER_YES = 1
export const IS_VERIFIED_BUYER_NO = 0

export const SORTING_OPTIONS = [
    {
        code: 'rating',
        value: 'DESC',
        lable: __("Rating"),
        active: false
    },
    {
        code: 'id',
        value: 'DESC',
        lable: __("Newest"),
        active: true
    }
]

export const FILTER_OPTIONS = [
    {
        id: 1,
        name: __('All Reviews'),
        value: 1000,
        from_to: "0",
        label: __('All Reviews'),
        active: true
    },
    {
        id: 2,
        name: __('5 Star'),
        value: 100,
        from_to: '100',
        label: __('5 Star'),
        active: false
    },
    {
        id: 3,
        name: __('4 Star'),
        value: 80,
        from_to: '80',
        label: __('4 Star'),
        active: false
    },
    {
        id: 4,
        name: __('3 Star'),
        value: 60,
        from_to: '60',
        label: __('3 Star'),
        active: false
    },
    {
        id: 5,
        name: __('2 Star'),
        value: 40,
        from_to: '40',
        label: __('2 Star'),
        active: false
    },{
        id: 6,
        name: __('1 Star'),
        value: 20,
        from_to: '20',
        label: __('1 Star'),
        active: false
    }
]

export const STATUS =[
    {
        id: 1,
        name: __('APPROVED'),
        value: 1,
        label: __('APPROVED')
    },
    {
        id: 2,
        name: __('Pending'),
        value: 2,
        label: __('Pending')
    },
    {
        id: 3,
        name: __('Not Approved'),
        value: 3,
        label: __('Not Approved')
    }
]



