/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
export class FieldSelectContainerPlugin {

    // Sort by id
    sortSelectOptionsAction = (args, callback, instance) => {
        const { selectOptions } = instance.props;

        const sortedOptions = selectOptions.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const textA = a.id;
            const textB = b.id;
            // eslint-disable-next-line no-nested-ternary
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return sortedOptions;
    };
}

export const { sortSelectOptionsAction } = new FieldSelectContainerPlugin();

export default {
    'Component/FieldSelect/Container': {
        'member-function': {
            sortSelectOptions: {
                position: 100,
                implementation: sortSelectOptionsAction
            }
        }
    }
};