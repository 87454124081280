import Loader from 'Component/Loader';
import AdvanceRecentReview from '../component/AdvanceRecentReview'

export class MyAccountDashboardComponentPlugin {

    renderMyAccountDashboard = (args, callback, instance) => {
        const { customer } = instance.props;

        return (
            <div block="MyAccountDashboard">
                <Loader isLoading={ !Object.keys(customer).length } />
                { instance.renderCustomerTable() }
                { instance.renderDefaultAddressTables() }
                { instance.renderCustomerPopup() }
                <AdvanceRecentReview />
            </div>
        );
    };
    
}

export const { renderMyAccountDashboard } = new MyAccountDashboardComponentPlugin();

export default {
    'Component/MyAccountDashboard/Component': {
        'member-function': {
            render: renderMyAccountDashboard
        }
    }
};