/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import getStore from 'Util/Store';
import { AMASTY_DELIVERY_CITY_UPDATE, AMASTY_DELIVERY_RENDER_FIELD } from '../util/Event/Events'
export const DeliveryDateDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/Amasty/DeliveryDate.dispatcher'
);

export class CheckoutAddressFormPlugin {

    renderfieldMap = (args, callback, instance) => {

        // Manage Edit Form Start
        const {
            address
        } = callback.props

        let {
            city_id,
            surb_id
        } = callback.state

        // Edit Form
        if (Object.keys(address).length > 0 && city_id == undefined && address.advance_city.city_id) {
            city_id = parseInt(address.advance_city.city_id)
        }

        var original = { ...args }

        const state = getStore().getState();

        // Check amasty_delivery_date enable or not
        if (state.ConfigReducer.amasty_delivery_date.general.enable) {

            // Manage City
            let deliverySurb = 'AmDeliverySurbDisable'
            var selectedCity = 0;
            var cityOptions = []
            if (state.DeliveryDateReducer.cities.length > 0) {
                cityOptions = state.DeliveryDateReducer.cities;
                if (city_id == "") {
                    selectedCity = state.DeliveryDateReducer.cities[0].value
                    this.onCityChange(state.DeliveryDateReducer.cities[0].value, callback)
                } else if (city_id != undefined) {
                    selectedCity = parseInt(city_id)
                    this.onCityChange(city_id, callback)
                }
            }

            // Manage Suburb
            var selectedSuburb = 0;
            var suburbOptions = []
            if (state.DeliveryDateReducer.suburb.length > 0 && city_id > 0) {
                deliverySurb = ''
                suburbOptions = state.DeliveryDateReducer.suburb
                selectedSuburb = state.DeliveryDateReducer.suburb[0].value
            }
            // Edit Form
            if (Object.keys(address).length > 0 && surb_id == undefined && address.advance_city.suburb_id) {
                selectedSuburb = address.advance_city.suburb_id
            }



            // Remove Old city format
            const customFields = {
                country_id: {
                    label: args.country_id.label,
                    onChange: args.country_id.onChange,
                    selectOptions: args.country_id.selectOptions,
                    type: args.country_id.type,
                    validation: args.country_id.validation,
                    value: args.country_id.value,
                    mix: { block: 'AmDeliveryDateCountryIdDisable' }
                },
                city: {
                    label: args.city.label,
                    onChange: args.city.onChange,
                    value: "-",
                    mix: { block: 'AmDeliveryDateCityDisable' }
                }, 
                city_id: {
                    label: __('City'),
                    validation: ['notEmpty'],
                    type: 'select',
                    value: selectedCity,
                    onChange: (value) => this.onCityChange(value, callback),
                    selectOptions: cityOptions,
                    validateSeparately: false
                },
                surb_id: {
                    label: __('Suburb'),
                    type: 'select',
                    value: selectedSuburb,
                    onChange: (value) => callback.setState({ surb_id: parseInt(value) }),
                    selectOptions: suburbOptions,
                    mix: { block: deliverySurb }
                }
            }

            original = { ...args, ...customFields }
        }

        return original
    };

    // Manage City Change
    onCityChange = async (cityId, callback) => {
        if (cityId > 0) {

            const {
                getMagentovnAdvancedCitySuburb
            } = callback.props

            await getMagentovnAdvancedCitySuburb(parseInt(cityId))

            await window.dispatchEvent(new CustomEvent(AMASTY_DELIVERY_CITY_UPDATE, { detail: parseInt(cityId) }));
        }

        await callback.setState({ city_id: parseInt(cityId) })

    }

    __construct = (args, callback, instance) => {

        const state = getStore().getState();

        // Check amasty_delivery_date enable or not
        if (state.ConfigReducer.amasty_delivery_date.general.enable) {

            const {
                getMagentovnAdvancedCities
            } = instance.props

            getMagentovnAdvancedCities()

            setTimeout(function () {
                instance.setState({ city: '-' })
            }, 5000)
        }

        return callback(...args)
    }

}

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const original = callback(...args);
    const state = getStore().getState();

    // Check amasty_delivery_date enable or not
    if (!state.ConfigReducer.amasty_delivery_date.general.enable) {
        return {
            ...original
        }
    }

    return {
        ...original,
        getMagentovnAdvancedCities: () => DeliveryDateDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getMagentovnAdvancedCities(dispatch)
        ),
        getMagentovnAdvancedCitySuburb: (cityId) => DeliveryDateDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getMagentovnAdvancedCitySuburb(dispatch, cityId)
        )
    };
};

export const { renderfieldMap, __construct } = new CheckoutAddressFormPlugin();

export default {
    'Component/MyAccountAddressForm/Component': {
        'member-function': {
            fieldMap: {
                position: 150,
                implementation: renderfieldMap
            },
            __construct: {
                position: 100,
                implementation: __construct
            }
        }
    },
    'Component/MyAccountAddressForm/Container/mapDispatchToProps': {
        function: [mapDispatchToProps]
    }
};