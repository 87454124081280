/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import DirectPayComponent from './DirectPay.component';

/** @namespace Component/DirectPay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    server_type: state.ConfigReducer.payment_tappay_server_type,
    app_id: state.ConfigReducer.payment_tappay_app_id,
    app_key: state.ConfigReducer.payment_tappay_app_key
});

/** @namespace Component/DirectPay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({

});

/** @namespace Component/DirectPay/Container */
export class DirectPayContainer extends PureComponent {

    static prime() {
        return new Promise((resolve, reject) => {
            TPDirect.card.getPrime(function (result) {
                if(result.msg == 'Success') {
                    resolve(result.card.prime)
                }else{
                    resolve(null)
                }
            })
        });
    }

    render() {
        const {setOrderButtonEnableStatus, setOrderButtonVisibility}=this.props
        setOrderButtonVisibility(true)
        setOrderButtonEnableStatus(false)
        return <DirectPayComponent {...this.props} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectPayContainer);
