/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © ScandiPWA, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author Akim Goncar <info@scandiweb.com>
 */

import SocialAuthentication from '../component/SocialAuthentication';

export class MyAccountOverlayPlugin {
    renderCreateAccount = (args, callback, instance) => (
            <>
                { callback.apply(instance, args) }
                <SocialAuthentication />
            </>
    );

    renderSignIn = (args, callback, instance) => (
            <>
                { callback.apply(instance, args) }
                <SocialAuthentication />
            </>
    );
}

export const { renderCreateAccount, renderSignIn } = new MyAccountOverlayPlugin();

export default {
    'Component/MyAccountOverlay/Component': {
        'member-function': {
            renderCreateAccount: [
                {
                    position: 126,
                    implementation: renderCreateAccount
                }
            ],
            renderSignIn: [
                {
                    position: 126,
                    implementation: renderSignIn
                }
            ]
        }
    }
};
