import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import AdvanceRecentReview from './AdvanceRecentReview.component';
import AheadworkAdvanceReviewQuery from "../../query/AheadworkAdvanceReview.query";
import { fetchQuery } from "Util/Request";
import { showNotification } from 'Store/Notification/Notification.action';
import { STATUS, PRODUCT_REVIEW_PAGE } from "../AheadworkAdvanceReview/AheadworkAdvanceReview.config";
import moment from 'moment';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';



/** @namespace Component/CheckoutBilling/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace Component/AdvanceRecentReview/Container */
export class AdvanceRecentReviewContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired
    };

    containerFunctions = () => ({
        getStatus: this.getStatus,
        getReviews: this.getReviews,
        getCreatedValue: this.getCreatedValue,
        goToProductReviewPage: this.goToProductReviewPage
    });

    __construct(props) {
        super.__construct(props);
        this.state = {
            isLoading: true,
            reviews: [],
            page_info: {}
        }

        this.getReviews()
    }

    getSortParams() {
        let sort = {
            id: 'DESC'
        }
        return sort
    }

    getFiltersParms() {
        return {}
    }

    async getReviews(pageSize = 2, currentPage = 1){

        const {
            showErrorNotification
        } = this.props

        await fetchQuery(
            AheadworkAdvanceReviewQuery.getCustomerAheadworksAdvancedReviewsQuery(
              this.getFiltersParms(),
              this.getSortParams(),
              pageSize,
              currentPage
          )).then(({getCustomerAheadworksAdvancedReviews: response}) => {
            this.setState({ reviews: response.items, page_info: response.page_info, isLoading: false })
          },(error)=>{ showErrorNotification(error[0].message) });
    }

    getStatus = (statusCode) => {
        let status = ''
        STATUS.map(function(value){
            if(value.id == statusCode) {
                status = value.label
            }
        })
        return status
    }

    getCreatedValue(date, format='DD/MM/YYYY') {
        return moment(new Date(date)).format(format)
    }

    goToProductReviewPage() {
        history.push({ pathname: appendWithStoreCode(`${ PRODUCT_REVIEW_PAGE }`) });
    }

    render() {
        return (
            <AdvanceRecentReview
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions() }
            />
        );
    }
}

export default connect(null, mapDispatchToProps)(AdvanceRecentReviewContainer);
