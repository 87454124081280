/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ProductReviewRating from 'Component/ProductReviewRating';
import './AdvanceRecentReview.style';
import Loader from "Component/Loader";


/** @namespace Component/AdvanceRecentReview/Component */
export class AdvanceRecentReview extends PureComponent {

    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        reviews: PropTypes.array.isRequired,
        getStatus: PropTypes.func.isRequired,
        getCreatedValue: PropTypes.func.isRequired,
        goToProductReviewPage: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);
    }

    render() {

        const {
            isLoading
        } = this.props

        return (
            <div className="review-dashboard">
                <Loader isLoading={isLoading} />
                { this.renderReviewNotifications() }
                { this.renderRecentReview() }
            </div>
        );
    }

    renderReviewNotifications() {
        const {
            goToProductReviewPage
        } = this.props
       return (
            <div className="notifications">
                <div className="box-title">
                    { __('Review notifications') }
                </div>
                <div className="notifications-text">
                    { __('You will receive notifications') }
                </div>
                <a className="action edit" href={void(0)} onClick={goToProductReviewPage.bind(this)}>
                    { __('Edit') }
                </a>   
            </div>
        );
    }

    renderRecentReview() {

        const {
            goToProductReviewPage
        } = this.props

        return(
            <div className="recent-review">
                <div className="recent-review-top">
                    <div className="box-title">
                        { __('My Recent Review') }
                    </div>
                    <a className="action view" href={void(0)} onClick={goToProductReviewPage.bind(this)}>
                        { __('View all') }
                    </a>
                </div>
                <table className="recent-table" elem="Table">
                    <thead>
                      { this.renderProductReviewHeadingRow() }
                    </thead>
                    <tbody>
                      { this.renderProductReviewRows() }
                    </tbody>
                </table>
            </div>
        );
    }
    renderProductReviewHeadingRow() {
        return (
            <tr>
                <th block="hidden-mobile">{ __('Created') }</th>
                <th>{ __('Product Name') }</th>
                <th mix={ { block: 'Sortable', elem:'Descend' } }>
                    { __('Status') }<span block="SortableIcon"></span>
                </th>
                <th>{ __('Rating') }</th>
                <th block="hidden-mobile" mix={ { block: 'Sortable', elem:'Ascend' } }>
                    { __('Summary') }<span block="SortableIcon"></span>
                </th>
            </tr>
        );
    }
    renderProductReviewRows() {
        const {
            reviews
        }= this.props

        if(reviews.length) {
            let rows = [];
            reviews.map((value) => {
                rows.push(this.renderProductReviewRow(value));
            })
            return rows
        }

        return null
    }

    renderProductReviewRow(review) {
        const {
            getStatus,
            getCreatedValue
        }= this.props
        return(
            <tr block="MyAccountProductReviewTableRow" key={review.id}>
                <td block="hidden-mobile">{ getCreatedValue(review.created_at) }</td>
                <td><span block="ProductReview" elem="Name">{ review.product_name }</span></td>
                <td>{ getStatus(review.status) }</td>
                <td>
                    <span block="ProductReview" elem="Rating">
                        <ProductReviewRating summary={ review.rating } />
                    </span>
                </td>
                <td block="hidden-mobile">{ review.summary }</td>
            </tr>
        );
    }
}

export default AdvanceRecentReview;
