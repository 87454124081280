/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_STORELIST = 'UPDATE_STORELIST';

/** @namespace Store/StoreSwitcher/Action/updateStoreList */

export const updateStoreList = (availableStores) => ({
    type: UPDATE_STORELIST,
    availableStores
});