/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const GET_CITIES = 'GET_CITIES';

export const IS_LOADING = 'IS_LOADING';

export const SET_SUBURB = 'SET_SUBURB';

/**
 * Update current Tickets with new breadcrumbs (rewrite if already exists).
 * @param  {Array<Object>} breadcrumbs List of breadcrumbs
 * @return {void}
 * @namespace Store/DeliveryDate/Action
 */
export const getCities = (cities) => ({
    type: GET_CITIES,
    cities
});

export const setSuburb = (suburb) => ({
    type: SET_SUBURB,
    suburb
});

export const isLoading = (loading) => ({
    type: IS_LOADING,
    loading
});


