/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import getStore from 'Util/Store';


/** @namespace Component/Klarna/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showNotification('error', message))
});


/** @namespace Component/Klarna/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({
    server_type: state.ConfigReducer.payment_tappay_server_type,
    app_id: state.ConfigReducer.payment_tappay_app_id,
    app_key: state.ConfigReducer.payment_tappay_app_key
});

/** @namespace Component/TapPayContainer/Container */
export class TapPayContainer extends PureComponent {
    
    static prime() {

        return new Promise((resolve, reject) => {
            const state = getStore().getState();
            const {
                payment_tappay_server_type,
                payment_tappay_app_id,
                payment_tappay_app_key
            }= state.ConfigReducer
            TPDirect.setupSDK(parseInt(payment_tappay_app_id), payment_tappay_app_key, payment_tappay_server_type)
            TPDirect.linePay.getPrime(function (result) {
                if(result.msg == 'Success') {
                    resolve(result.prime)
                }else{
                    resolve(null)
                }
            })
        });
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TapPayContainer);
