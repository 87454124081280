import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReviewItemInfo from './ReviewItemInfo.component';
import moment from 'moment';

import AheadworkAdvanceReviewQuery from '../../query/AheadworkAdvanceReview.query'
import { fetchMutation } from "Util/Request";
import { UPDATE_REVIEW } from '../../util/Event/Events'

/** @namespace Component/ReviewItemInfoContainer/Container */
export class ReviewItemInfoContainer extends PureComponent {

    static propTypes = {
        review: PropTypes.object.isRequired
    };

    containerFunctions = {
        formatDate: this.formatDate.bind(this),
        addNewCommentFun: this.addNewCommentFun.bind(this),
        updateNickname: this.updateNickname.bind(this),
        updateSummary: this.updateSummary.bind(this),
        saveCommentClickError: this.saveCommentClickError.bind(this),
        saveCommentClickSuccess: this.saveCommentClickSuccess.bind(this),
        cancelBtn: this.cancelBtn.bind(this)
    }

    __construct(props) {
        super.__construct(props);

        this.state ={
            isLoading: false,
            addComment: true,
            nickname: "",
            summary: ""
        }
    }

    formatDate(date, format = 'YYYY-MM-DD') {
        var _date = new Date(date);
        return moment(_date).format(format)
    }

    addNewCommentFun() {
        this.setState({addComment: false})
    }
    updateNickname(value) {
        this.setState({nickname: value})
    }
    updateSummary(value) {
        this.setState({summary: value})
    }

    cancelBtn(){
        this.setState({addComment: true})
    }

    saveCommentClickError() {
        // Implement your custom error
    }

    saveCommentClickSuccess() {

        const {
            nickname,
            summary
        } = this.state

        const {
            review:{
                id
            }
        } = this.props

        const input = {
            review_id: id,
            nickname: nickname,
            content: summary
        }

        fetchMutation(AheadworkAdvanceReviewQuery.createAheadworksAdvancedReviewComment(input)).then(
            ({ createAheadworksAdvancedReviewComment: response }) => {
              this.setState({ isLoading: false, addComment: true, nickname: "", summary: "" });
              window.dispatchEvent(new CustomEvent(UPDATE_REVIEW));
            }
        );
    }

    render() {
        return (
            <ReviewItemInfo 
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default ReviewItemInfoContainer;
