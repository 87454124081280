/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import "./DirectPay.component.style"

/** @namespace Component/DirectPay/Component */
export class DirectPay extends PureComponent {

    defaultCardViewStyle= {
        color: 'initial',
        fontSize: '14px',
        lineHeight: '32px',
        fontWeight: '300',
        errorColor: 'red',
        placeholderColor: ''
    }

    setupDirectPayApi() {
        const {
            server_type,
            app_id,
            app_key,
            setOrderButtonEnableStatus
        }=this.props

        TPDirect.setupSDK(parseInt(app_id), app_key, server_type)
        TPDirect.card.setup('#tappay-cardview-container', this.defaultCardViewStyle);
        TPDirect.card.onUpdate(function (update) {
            if(update.canGetPrime){
                setOrderButtonEnableStatus(true)
            }else{
                setOrderButtonEnableStatus(false)
            }
        })
    }

    render() {
        let that = this
        const { setLoading, setOrderButtonEnableStatus }= this.props
        setLoading(true)
        setTimeout(function(){
            that.setupDirectPayApi()
            setOrderButtonEnableStatus(false)
            setLoading(false)
        }, 5000)
        
        return (
            <>
                <div id="tappay-cardview-container" className="control"></div>
            </>
        );
    }
}

export default DirectPay;
