/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import { Field } from 'Util/Query';

/** @namespace Elightwalk/DeliveryDateQuery/Query */
export class DeliveryDateQuery {
    setAmastyDeliveryDate(cartId, deliveryDate, deliveryTime, deliveryComment) {
        return new Field('setAmastyDeliveryDate')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('delivery_date', 'String!', deliveryDate)
            .addArgument('delivery_time', 'Int', deliveryTime)
            .addArgument('delivery_comment', 'String', deliveryComment)
            .addFieldList(this._getAmastyResponse());
    }

    _getAmastyResponse()
    {
        return [
            'error',
            'message'
        ]
    }

    getMagentovnAdvancedCities() {
        return new Field('getMagentovnAdvancedCities')
            .addFieldList([
                'label',
                'title',
                'value'
            ]);
    }

    getMagentovnAdvancedCitySuburb(cityId) {
        return new Field('getMagentovnAdvancedCitySuburb')
            .addArgument('city_id', 'Int', cityId)
            .addFieldList([
                'label',
                'title',
                'value'
            ]);
    }

    getDisableDayPerCity(cityId) {
        return new Field('getDisableDayPerCity')
            .addArgument('city_id', 'Int', cityId);
    }
}

export default new (DeliveryDateQuery)();
