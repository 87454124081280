/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

export class ConfigQueryPlugin {

    _getStoreConfigFieldsAction = (args, callback, instance) => {
        return [
            ...callback(...args),
            new Field('amasty_delivery_date').addFieldList([
                this._getGeneralConfigFields(), 
                this._getDateConfigFields(), 
                this._getTimeConfigFields(), 
                this._getCommentConfigFields()
            ])
        ];
    };

    _getGeneralConfigFields() {
        return new Field('general')
            .addFieldList([
                'enable',
                'disabled_days',
                'min_days',
                'max_days',
                this._getDaysFields(),
                this._getDintervalsFields(),
                this._getTintervalsFields()
            ]);
    }

    _getDateConfigFields() {
        return new Field('date')
            .addFieldList([
                'format',
                'required',
                'enabled_default',
                'default_value',
                'note',
                'enabled_customer_groups',
                'customer_groups',
                'enabled_carriers',
                'carriers'
            ]);
    }

    _getTimeConfigFields() {
        return new Field('time')
            .addFieldList([
                'enabled_time',
                'required',
                'enabled_default',
                'default_value',
                'note',
                'enabled_customer_groups',
                'customer_groups',
                'enabled_carriers',
                'carriers'
            ]);
    }

    _getCommentConfigFields() {
        return new Field('comment')
            .addFieldList([
                'enabled_comment',
                'required',
                'maxlength',
                'note',
                'enabled_customer_groups',
                'customer_groups',
                'enabled_carriers',
                'carriers'
            ]);
    }

    _getDintervalsFields() {
        return new Field('dintervals')
            .addFieldList([
                this._geDateFields('from'),
                this._geDateFields('to')
            ]);
    }

    _getTintervalsFields() {
        return new Field('tintervals')
            .addFieldList(
                this._geOptionsFields()
            );
    }

    _geDateFields(key) {
        return new Field(key)
            .addFieldList([
                'year',
                'month',
                'day'
            ]);
    }

    _geOptionsFields() {
        return [
            'label',
            'value'
        ];
    }

    _getDaysFields() {
        return new Field('days')
            .addFieldList(this._geOptionsFields());
    }
}

export const { _getStoreConfigFieldsAction } = new ConfigQueryPlugin();

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _getStoreConfigFieldsAction
        }
    }
};