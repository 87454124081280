import { isSignedIn } from 'Util/Auth';
import CheckoutQuery from 'Query/Checkout.query';
import { getGuestQuoteId } from 'Util/Cart';
import {
    fetchMutation,
} from 'Util/Request';
import CheckoutSuccess from 'Component/CheckoutSuccess';
import { SMILEPAY_ATM_CODE } from '@elightwalk/smilepay/src/component/SmilepayatmOrderSuccess/SmilepayatmOrderSuccess.config';


const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {

    const { paymentMethod: { code, additional_data, purchase_order_number } } = args[0];

    if(code == SMILEPAY_ATM_CODE) {
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        if (!isCustomerSignedIn && !guest_cart_id) {
            return;
        }

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [code]: additional_data,
                    purchase_order_number
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const { placeOrder: { order:{ order_id } } } = orderData;
            const { placeOrder: { order } } = orderData;

            await instance.setState({order: order})

            instance.setDetailsStep(order_id);

        } catch (e) {
            instance._handleError(e);
        }
    }else{
        return callback(...args)
    }
}

const renderDetailsStep = (args, callback, instance) => {
    if(instance.props.order) {
        const {
            orderID,
            isEmailAvailable,
            email,
            billingAddress: {
                firstname,
                lastname
            },
            order
        } = instance.props;
    
        return (
            <CheckoutSuccess
              email={ email }
              firstName={ firstname }
              lastName={ lastname }
              isEmailAvailable={ isEmailAvailable }
              orderID={ orderID }
              order={ order }
            />
        );
    }

    return callback(...args)
    
}

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder:{
                position: 1000,
                implementation: savePaymentMethodAndPlaceOrder
            }
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep:{
                position: 1000,
                implementation: renderDetailsStep
            }
        },
    }
};