/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { GET_CITIES, IS_LOADING, SET_SUBURB } from './DeliveryDate.action';

/** @namespace Store/AmastyDeliveryDate/Reducer/getInitialState */
export const getInitialState = () => ({
    cities: [],
    suburb: [],
    loading: true,
        
});

/** @namespace Store/AmastyDeliveryDate/Reducer */
export const DeliveryDateReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case GET_CITIES:
        const { cities } = action;

        return {
            ...state,
            cities
        };
    case SET_SUBURB:
        const { suburb } = action;

        return {
            ...state,
            suburb
        };
    case IS_LOADING:
        const { loading } = action;

        return {
            ...state,
            loading
        };

    default:
        return state;
    }
};

export default DeliveryDateReducer;
