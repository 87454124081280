/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import OrderDeliveryDate from './OrderDeliveryDate.component'

/** @namespace Component/OrderDeliveryDateContainer/Container */
export class OrderDeliveryDateContainer extends PureComponent {

    static propTypes = {
        order: PropTypes.object.isRequired
    };

    __construct(props) {
        super.__construct(props);
    }

    render() {
        return (
            <OrderDeliveryDate
                { ...this.props }
            />
        );
    }
}

export default OrderDeliveryDateContainer;
