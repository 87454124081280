import { Field } from 'Util/Query';

/** @namespace Elightwalk/AheadworksAdvancedReviewsGraphql/Query */
export class AheadworkAdvanceReviewQuery {
    /**
     * Get advance reviews
     *
     * @returns {*}
     */
    getAheadworksAdvancedReviewsQuery(filters, sort, pageSize, currentPage) {
        return new Field('getAheadworksAdvancedReviews')
            .addArgument('filters', 'AheadworksAdvancedReviewFilterInput!', filters)
            .addArgument('sort', 'AheadworksAdvancedReviewSortInput', sort)
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('currentPage', 'Int', currentPage)
            .addFieldList(this._getAheadworksAdvancedReviews());
    }

    /**
     * Get advance reviews
     *
     * @returns {*}
     */
    getCustomerAheadworksAdvancedReviewsQuery(filters, sort, pageSize, currentPage) {
        return new Field('getCustomerAheadworksAdvancedReviews')
            .addArgument('filters', 'AheadworksAdvancedReviewFilterInput', filters)
            .addArgument('sort', 'AheadworksAdvancedReviewSortInput', sort)
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('currentPage', 'Int', currentPage)
            .addFieldList(this._getAheadworksAdvancedReviews());
    }

    _getAheadworksAdvancedReviews() {
        return [
            this._getReviewItems(),
            this._getPageInfo()
        ]
    }

    _getReviewItems() {
        return new Field('items').addFieldList(this._getAheadworksAdvancedReview())
    }

    _getPageInfo() {
        return new Field('page_info').addFieldList([
            'current_page',
            'page_size',
            'total_pages'
        ])
    }

    _getAheadworksAdvancedReview() {
        return [
            'id',
            'created_at',
            'rating',
            'summary',
            'content',
            'pros',
            'cons',
            'store_id',
            'product_id',
            'product_name',
            'product_url',
            'status',
            'author_type',
            'email',
            'is_featured',
            'is_verified_buyer',
            'votes_positive',
            'votes_negative',
            'shared_store_ids',
            'nickname',
            this._getAheadworksAdvancedReviewComments(),
            'product_recommended',
            'order_item_id'
        ];
    }

    _getAheadworksAdvancedReviewComments(){

        return new Field('comments').addFieldList([
            'id',
            'type',
            'review_id',
            'status',
            'nickname',
            'content',
            'created_at'
        ])
    }
    /**
     * Get advance review summery through product id
     *
     * @returns {*}
     */
    getAheadworksAdvancedReviewsSummery(productId) {
        return new Field('getAheadworksAdvancedReviewsSummery')
            .addArgument('product_id', 'Int!', productId)
            .addFieldList(this._getAheadworksAdvancedReviewsSummery());
    }

    _getAheadworksAdvancedReviewsSummery() {
        return [
            'recommended_customer',
            this._getStarsDetails('stars1'),
            this._getStarsDetails('stars2'),
            this._getStarsDetails('stars3'),
            this._getStarsDetails('stars4'),
            this._getStarsDetails('stars5')
        ]
    }

    _getStarsDetails(key) {
        return new Field(key).addFieldList([
            'percentage_reviews',
            'total_reviews'
        ])
    }

    // Create New Review
    createAheadworksAdvancedReview(input) {
        return new Field('createAheadworksAdvancedReview')
            .addArgument('input', 'AheadworksAdvancedReviewInput', input)
            .addFieldList(this._getAheadworksAdvancedReview());
    }

    // Add New Comments
    createAheadworksAdvancedReviewComment(input){
        return new Field('createAheadworksAdvancedReviewComment')
            .addArgument('input', 'CreateAheadworksAdvancedReviewCommentInput', input)
            .addFieldList([
                'id',
                'type',
                'review_id',
                'status',
                'nickname',
                'content',
                'created_at'
            ]);
    }
    // Email Subscriber Config
    getEmailSubscriberConfig() {
        return new Field('getEmailSubscriberConfig')
            .addFieldList([
                'is_review_approved_email_enabled',
                'is_new_comment_email_enabled',
                'is_review_reminder_email_enabled'
            ]);
    }

    updateEmailSubscriberConfig(isReviewApprovedEmailEnabled, isNewCommentEmailEnabled, isReviewReminderEmailEnabled) {
        return new Field('updateEmailSubscriberConfig')
            .addArgument('is_review_approved_email_enabled', 'Boolean!', isReviewApprovedEmailEnabled)
            .addArgument('is_new_comment_email_enabled', 'Boolean!', isNewCommentEmailEnabled)
            .addArgument('is_review_reminder_email_enabled', 'Boolean!', isReviewReminderEmailEnabled)
            .addFieldList([
                'is_review_approved_email_enabled',
                'is_new_comment_email_enabled',
                'is_review_reminder_email_enabled'
            ]);
    }
}

export default new (AheadworkAdvanceReviewQuery)();
