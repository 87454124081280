/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_AHEADWORKSFAQINDEX = 'UPDATE_AHEADWORKSFAQINDEX';

export const IS_LOADING = 'IS_LOADING';

export const UPDATE_AHEADWORKSFAQSEARCHINDEX = 'UPDATE_AHEADWORKSFAQSEARCHINDEX';

export const UPDATE_AHEADWORKSFAQARTICLECONTENT = 'UPDATE_AHEADWORKSFAQARTICLECONTENT';

/** @namespace Store/Faqpage/Action */

export const updateAheadworksFaqIndex = (getAheadworksFaqIndex) => ({
    type: UPDATE_AHEADWORKSFAQINDEX,
    getAheadworksFaqIndex
});

export const isLoading = (loading) => ({
    type: IS_LOADING,
    loading
});

export const updateAheadworksFaqSearchIndex = (getAheadworksFaqSearchIndex) => ({
    type: UPDATE_AHEADWORKSFAQSEARCHINDEX,
    getAheadworksFaqSearchIndex
});

export const updateAheadworksFaqArticleContent = (getAheadworksFaqArticleContent) => ({
    type: UPDATE_AHEADWORKSFAQARTICLECONTENT,
    getAheadworksFaqArticleContent
});