/**
 * Event names
 *
 * @type {string}
 */
export const AMASTY_DELIVERY_DATE_UPDATE = 'amasty_delivery_date_update';
export const AMASTY_DELIVERY_DATE_SET = 'amasty_delivery_date_set';
export const AMASTY_DELIVERY_DATE_SET_RESPONSE = 'amasty_delivery_date_set_response';
export const AMASTY_DELIVERY_CITY_UPDATE = 'amasty_delivery_city_update';
export const AMASTY_DELIVERY_RENDER_FIELD = 'amasty_delivery_city_update';



