/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const _getOrderByIdFieldAction = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('amasty_delivery').addFieldList([
            'delivery_comment',
            'delivery_time_id',
            'delivery_date',
            'delivery_time'
        ])
    ];
};

const _prepareOrderCustomerAddressInfoFields = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('advance_city').addFieldList([
            'city_id',
            'city_label',
            'suburb',
            'suburb_id'
        ])
    ];
};

export default {
    'Query/Order': {
        'member-function':{
            _getOrderItemsFields: {
                position: 100,
                implementation: _getOrderByIdFieldAction
            },
            _prepareOrderCustomerAddressInfoFields: {
                position: 100,
                implementation: _prepareOrderCustomerAddressInfoFields
            }
        }
    }
};