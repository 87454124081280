/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const _getAddressFields = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('advance_city').addFieldList([
            'city_id',
            'city_label',
            'suburb',
            'suburb_id'
        ])
    ];
};

export default {
    'Query/MyAccount': {
        'member-function': {
            _getAddressFields: {
                position: 100,
                implementation: _getAddressFields
            }
        }
    }
};