
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_AHEADWORKSFAQINDEX, IS_LOADING, UPDATE_AHEADWORKSFAQSEARCHINDEX, UPDATE_AHEADWORKSFAQARTICLECONTENT } from './Faqpage.action';

/** @namespace Store/Faqpage/Reducer/getInitialState */
export const getInitialState = () => ({
    getAheadworksFaqIndex: {},
    loading: true,
    getAheadworksFaqSearchIndex: {},
    getAheadworksFaqArticleContent: {}
});

/** @namespace Store/Faqpage/Reducer */
export const FaqpageReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case UPDATE_AHEADWORKSFAQINDEX:
            const { getAheadworksFaqIndex } = action;
            return {
                ...state,
                getAheadworksFaqIndex
            };

        case IS_LOADING:
            const { loading } = action;

            return {
                ...state,
                loading
            };

        case UPDATE_AHEADWORKSFAQSEARCHINDEX:
            const { getAheadworksFaqSearchIndex } = action;
            return {
                ...state,
                getAheadworksFaqSearchIndex
            };

        case UPDATE_AHEADWORKSFAQARTICLECONTENT:
            const { getAheadworksFaqArticleContent } = action;
            return {
                ...state,
                getAheadworksFaqArticleContent
            };

        default:
            return state;
    }
};

export default FaqpageReducer;