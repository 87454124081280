import ProductReviewRating from 'Component/ProductReviewRating';

export const afterRenderReviews = (args, callback, instance) => {

    const {
        product
    } = instance.props;

    if (product.aheadworks_advanced_review) {

        const {
            product: {
                aheadworks_advanced_review: {
                    aggregated_rating,
                    reviews_count
                }
            }
        } = instance.props;

        if (!aggregated_rating) {
            return null;
        }

        const ONE_FIFTH_OF_A_HUNDRED = 20;
        const rating = parseFloat(aggregated_rating / ONE_FIFTH_OF_A_HUNDRED).toFixed(2);

        return (
            <div
            block="ProductActions"
            elem="Reviews"
            >
                <ProductReviewRating summary={ aggregated_rating || 0 } />
                <p block="ProductActions" elem="ReviewLabel">
                    { rating }
                    <span>{ __('%s reviews', reviews_count) }</span>
                </p>
            </div>
        );
    }

    return null;
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderReviews: afterRenderReviews
        }
    }
};